import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Login from './Pages/PublicPages/LoginScreen';
import SignUp from './Pages/PublicPages/SignUpScreen';
import GlobalContext from './GlobalContext';
import { useContext } from 'react';
import MainScreen from './Pages/AuthPages/MainScreen';
import PasswordRecoveryScreen from './Pages/PublicPages/PasswordRecoveryScreen';
import EmailSent from './Pages/PublicPages/EmailSent';
import CodeConfirm from './Pages/PublicPages/CodeConfirmation';
import SplashScreen from './Pages/PublicPages/SplashScreen';

export default function RoutesArtefacto() {
  const { isLoggedIn, isLoading } = useContext(GlobalContext);

  return (
    <BrowserRouter>
      <Routes>
        {isLoading ? (
          <Route path="*" element={<SplashScreen />} />
        ) : isLoggedIn ? (
          <>
            <Route path="/" element={<MainScreen />} />
            <Route path="*" element={<MainScreen />} />
          </>
        ) : (
          <>
            <Route path="/email-sent" element={<EmailSent />} />
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/activate" element={<CodeConfirm />} />
            <Route path="/recovery" element={<PasswordRecoveryScreen />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}
