import { useCallback, useContext } from 'react';
import { Handle, Position } from 'reactflow';
import GlobalContext from '../../../GlobalContext';

const handleStyle = { left: 10 };

export default function MainNode({ data, isConnectable }) {
  const { title, description, colors, firstElement } = data;

  const onChange = useCallback((evt) => {}, []);

  const { GlobalActions, langSelected } = useContext(GlobalContext);

  return (
    <div
      className="text-updater-node"
      style={{ backgroundColor: colors.color2 }}
      key={Math.random() + ''}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        className="mother-handler"
      />

      <div
        className="header-text-node"
        style={{ backgroundColor: colors.color1 }}
      >
        {title[langSelected === 1 ? 'es' : 'en'].slice(0, 23)}
      </div>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
        className="initial-handler"
      />
      <div className="text-node">
        {description[langSelected === 1 ? 'es' : 'en'].slice(0, 120)}
      </div>
    </div>
  );
}
