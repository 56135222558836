import { useCallback, useContext } from 'react';
import { Handle, Position } from 'reactflow';
import GlobalContext from '../../../GlobalContext';
import { vocabulary } from '../../../services/vocabulary';

const handleStyle = { left: 10 };

export default function ChatNode({ id, isConnectable, onDoubleClick }) {
  const { langSelected } = useContext(GlobalContext);

  return (
    <div
      className="text-updater-node"
      style={{ backgroundColor: '#11552E' }}
      key={Math.random() + ''}
      onDoubleClick={onDoubleClick}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        className="chat-handler"
      />
      <div className="header-text-node" style={{ backgroundColor: '#1F8547' }}>
        {vocabulary.chat[langSelected]}
      </div>

      <div className="text-node">
        {vocabulary.speakWithYourConfiguration[langSelected]}
      </div>
    </div>
  );
}
