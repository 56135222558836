export const vocabulary = {
  //left menu
  buttonSubmit: ['Submit', 'Enviar'],
  myAccount: ['My Account', 'Mi cuenta'],
  movies: ['Movies', 'Películas'],
  leave: ['Log Out', 'Cerrar sesión'],
  //login
  startRoadmovie: ['Start Roadmovie', 'Iniciar Roadmovie'],

  userName: ['Username', 'Usuario'],
  password: ['Password', 'Contraseña'],
  errorMailPassword: [
    'Wrong email or password',
    'Correo electrónico o contraseña incorrectos',
  ],
  passwordForgotten: ['Forgot your password?', '¿Olvidaste tu contraseña?'],
  enter: ['Log in', 'Iniciar sesión'],
  doNotHaveAccount: ['Create an account', 'Crear una cuenta'],
  termsAndConditions: [
    'By continuing, I accept terms and conditions',
    'Al continuar, acepto los términos y condiciones',
  ],
  doNotRegistered: ['Create an account', 'Crear una cuenta'],
  //sign-up
  passwordLongitudError: [
    'Password must be at least 6 characters',
    'La contraseña debe tener mínimo 6 caracteres',
  ],
  emailErrorBlank: [
    'Invalid email address',
    'Dirección de correo electrónico no válida',
  ],
  passwordRepeatSymbolsError: [
    'Password must not contain repeated characters in a row',
    'La contraseña no debe contener caracteres repetidos consecutivamente',
  ],
  register: ['Sign up', 'Registrarse'],
  namesAndSurnames: ['Full name', 'Nombre completo'],
  email: ['Email', 'Correo electrónico'],
  confirmPassword: ['Confirm password', 'Confirmar contraseña'],
  passwordDoesNotMatch: [
    'Passwords do not match',
    'Las contraseñas no coinciden',
  ],
  reestablishPassword: ['Reset password', 'Reestablecer contraseña'],
  haveAccount: ['I have an account. Log in', 'Ya tengo cuenta. Iniciar sesión'],
  termsAndConditionsHeader: ['Terms & Conditions', 'Términos y Condiciones'],
  agree: ['Agree', 'Aceptar'],
  enterCode: ['Enter code', 'Ingresar código'],
  cancel: ['Cancel', 'Cancelar'],
  //confirmation code
  confirmationCode: ['Confirmation code', 'Código de confirmación'],
  sendCode: ['Send code', 'Enviar código'],
  incorrectCode: ['Incorrect code', 'Código incorrecto'],
  emailSent: [
    'Check your email to activate your account',
    'Revise su correo para activar su cuenta',
  ],
  emailError: ['Email already exists', 'El correo ya existe'],
  //small screen
  needMoreResolution: [
    'Higher screen resolution required',
    'Se requiere mayor resolución de pantalla',
  ],
  resolutionWarning: [
    'Use a computer for best experience',
    'Use un computador/ordenador para una mejor experiencia',
  ],
  //Header
  archives: ['Road movies', 'Road movies'],
  myProjects: ['My projects', 'Mis Proyectos'],
  sources: ['Movie database', 'Bases de datos del cine'],
  exit: ['Log out', 'Cerrar sesión'],
  saveProject: ['Save Road movie', 'Guardar Road movie'],
  uploadProject: ['Upload Road movie', 'Subir Road movie'],
  newProject: ['New Road movie', ' Nuevo Road movie'],
  newSource: ['New project', 'Nuevo proyecto'],
  editSource: ['Edit projects', 'Editar proyectos'],
  mySources: ['My projects', 'Mis proyectos'],
  downloadProject: ['Download Road movie', 'Descargar Road movie'],
  chooseProject: ['Choose project', 'Eligir proyecto'],
  about: ['Help', 'Ayuda'],
  //Chat
  chat: ['Chat', 'Chat'],
  speakWithYourConfiguration: [
    'Chat with your Road movie',
    'Habla con tu Road movie',
  ],
  writeQuestion: ['What do you want to know?', '¿Qué quieres saber?'],
  ready: [
    'Road movie is ready to answer you question ',
    'Road movie esta listo para responder tus preguntas',
  ],
  myNotes: ['My notes', 'Mis notas'],
  myProject: ['My project', 'Mi proyecto'],
  dragNDropFileHere: ['Drag and drop your file', 'Arrastrar archivo'],
  selectFile: ['Select file', 'Selecciona archivo'],
  find: ['Find', 'Buscar'],
  projectName: ['Road movie name', 'Nombre de Road movie'],
  directorName: ['Director`s name', 'Nombre del director/a'],
  productorName: ['Producer`s name', 'Nombre del productor/a'],
  year: ['Year', 'Año'],
  duration: ['Duration', 'Duración'],
  genre: ['Genre', 'Género'],
  state: ['Project stage', 'Estado del proyecto'],
  logLine: ['Logline', 'Logline'],
  sinopsis: ['Synopsis', 'Sinopsis'],
  makeSource: ['Create project', 'Crear proyecto'],
  editFile: ['Edit file', 'Editar archivo'],
  name: ['Name', 'Nombre'],
  guide: ['Guide', 'Guion'],
  chooseSource: ['Choose project', 'Elegir proyecto'],
  changeSource: ['Edit project', 'Edit project'],
  deleteSource: ['Delete project', 'Eliminar proyecto'],
  changeFile: ['Change file', 'Cambiar archivo'],
  areYouSureToDelete: [
    'Are you sure you want to delete this project?',
    '¿Estas seguro que quieres eliminar este proyecto?',
  ],
  deleteText: [
    'Deleted projects cannot be recovered',
    'Los proyectos eliminados no podrán ser recuperados',
  ],
  deleteAccount: ['Delete account', 'Eliminar cuenta'],
  actualize: ['Update account', 'Actualizar cuenta'],
  areYouSureToDeleteAccount: [
    'Are you sure you want to delete this account?',
    '¿Estás seguro/a que quieres eliminar esta cuenta?',
  ],
  deleteTextAccount: [
    'All data associated with this account will be lost!',
    'Todos los datos asociados a esta cuenta se perderán',
  ],

  /*alert*/
  sourceCreated: [
    'Project created successfully',
    'Proyecto creada correctamente',
  ],
  sourceCreatedText: [
    'The project has been successfully created and added to your account',
    'El proyecto ha sido creado correctamente y añadido a tu cuenta',
  ],
  sourceDeleted: [
    'Project deleted successfully',
    'Proyecto eliminado correctamente',
  ],
  sourceDeletedText: [
    'The project has been successfully Deleted from your account',
    'El proyecto ha sido eliminado de tu cuenta correctamente',
  ],
  dropFileHere: ['Drop file here', 'Arrastra archivo'],
  save: ['Save', 'Guardar'],
  fiction: ['Fiction', 'Ficción'],
  nonFiction: ['Non-Fiction', 'No-Ficción'],
  experimental: ['Experimental', 'Experimental'],
  others: ['Others', 'Otros'],
  development: ['Development', 'Desarrollo'],
  production: ['Production', 'Producción'],
  postProduction: ['Postproduction', 'Postproducción'],
  distribution: ['Distribution', 'Distribución'],
  fileDeleted: ['File successfully deleted', 'Archivo eliminado correctamente'],
  fileDeletedText: [
    'File successfully deleted',
    'Archivo eliminado correctamente',
  ],
  sourceUpdated: ['Project updated', 'Proyecto actualizado'],
  sourceUpdatedText: [
    'Project updated successfully',
    'Proyecto actualizado correctamente',
  ],
  fileDescription: [
    'File description updated',
    'Descripción del archivo actualizada',
  ],
  fileDescriptionText: [
    'File description updated successfully',
    'Descripción del archivo actualizada correctamente',
  ],
  savedProject: ['The project has been saved', 'El proyecto ha sido guardado'],
  savedProjectText: [
    'The project has been saved successfully',
    'El proyecto ha sido guardado correctamente',
  ],
  changeProject: ['Edit Road movies', 'Editar Road movies'],
  areYouSureToDeleteProject: [
    'AAre you sure you want to delete this project?',
    '¿Estás seguro/a que deseas eliminar este proyecto?',
  ],
  deleteTextProject: [
    'Once a project is deleted, it cannot be restored. Please proceed with caution',
    'Una vez que un proyecto se elimine, no podrá ser restaurado. Por favor, proceda con precaución',
  ],

  /*project*/
  newProjectName: ['Rename Road movie', 'Renombrar Road movie'],
  projectList: ['Road movies', 'Road movies'],
  projectDelete: ['Delete project', 'Eliminar proyecto'],
  created: ['Created:', 'Creado:'],
  loadProject: ['Load Road movies', 'Cargar Road movies'],
  lastProject: ['Recently loaded', 'Cargados recientemente'],
  /*Conditions*/
  conditions: ['How to use Road movie', '¿Cómo usar Road movie?'],
  understand: ['Ok', 'Ok'],
  /*Misceláneos*/
  writeSomething: ['Type something here...', 'Escribe algo aquí...'],
  recentSources: ['My recent projects', 'Mis proyectos recientes'],
};
