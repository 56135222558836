import * as React from 'react';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { vocabulary } from '../../../../services/vocabulary';
import GlobalContext from '../../../../GlobalContext';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function NewProject({
  newProjectOpen,
  setNewProjectOpen,
  setAlertOpen,
  setHeaderText,
  setAlertText,
  saveConfig,
  setNewProjectExist,
  newProjectExist,
}) {
  const { GlobalActions, langSelected } = React.useContext(GlobalContext);

  const [projectName, setProjectName] = React.useState('');
  const handleClick = async () => {
    saveConfig(projectName);
    setNewProjectExist(!newProjectExist);
    setAlertOpen(true);
    setHeaderText(vocabulary.savedProject[langSelected]);
    setAlertText(vocabulary.savedProjectText[langSelected]);
    setNewProjectOpen(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={() => {
          setNewProjectOpen(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={newProjectOpen}
      >
        <DialogTitle
          sx={{ m: 0, fontSize: 'medium', fontWeight: 'bolder' }}
          id="customized-dialog-title"
        >
          {vocabulary.saveProject[langSelected]}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setNewProjectOpen(false);
          }}
          sx={{
            position: 'absolute',
            right: 15,
            top: 20,
            color: 'white',
            backgroundColor: '#5F5DF3',
            width: '20px',
            height: '20px',
            '&:hover': {
              backgroundColor: 'rgba(95, 93, 243, 0.8)',
            },
          }}
        >
          <CloseIcon
            sx={{
              width: '15px',
              height: '15px',
            }}
          />
        </IconButton>
        <DialogContent dividers>
          <div className="new-project">
            <div className="label-new-project">
              {vocabulary.newProjectName[langSelected]}
            </div>

            <TextField
              variant="outlined"
              autoComplete="project name"
              fullWidth
              size="small"
              margin="normal"
              placeholder={vocabulary.projectName[langSelected]}
              name="projectName"
              value={projectName}
              onChange={(e) => {
                setProjectName(e.target.value);
              }}
              style={{ backgroundColor: 'white' }}
            />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            margin: '10px',
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            style={{
              width: '40%',
              fontSize: '10px',
              backgroundColor: ' #EE4037',
              color: 'white',
              marginTop: 'auto',
              marginBottom: 'auto',
              marginRight: '20%',
              borderRadius: '5px',
              textTransform: 'none',
            }}
            fullWidth
            margin="normal"
            onClick={handleClick}
          >
            {vocabulary.saveProject[langSelected]}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            style={{
              width: '40%',
              fontSize: '10px',
              borderRadius: '5px',
              backgroundColor: '#5A58E6',
              color: 'white',
              marginTop: 'auto',
              marginBottom: 'auto',
              textTransform: 'none',
            }}
            fullWidth
            margin="normal"
            onClick={() => {
              setNewProjectOpen(false);
            }}
          >
            {vocabulary.cancel[langSelected]}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
