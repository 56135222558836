import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../GlobalContext';
import { useNavigate } from 'react-router-dom';

import logoIcon from './../../images/logo.png';
import backIcon from '../../images/back-arrow.png';
import { vocabulary } from '../../services/vocabulary';

export default function EmailSent() {
  const { menuMovieSelected, GlobalActions, langSelected } =
    useContext(GlobalContext);

  const [lang, setLang] = useState(0);

  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);
  const navigate = useNavigate();
  const navigateBack = () => {
    navigate('/');
  };

  setTimeout(navigateBack, 4000);

  return (
    <div className="login-screen">
      <div className="image-login"></div>

      <div className="login-box-mail-sent">
        <div className="space-email-sent">
          <img
            src={backIcon}
            alt="back-icon"
            width="50px"
            className="logout-icon"
            onClick={navigateBack}
          />
        </div>
        <div className="space-email-sent">
          <div className="login-header"> {vocabulary.emailSent[lang]}</div>
        </div>
      </div>
    </div>
  );
}
