import mobile from '../../images/mobile-screen.jpeg';
import tablet from '../../images/tablet-screen.jpeg';
import { useMediaQuery } from 'react-responsive';

export default function MobileScreen() {
  const isSmallScreen = useMediaQuery({ maxWidth: 599 });
  console.log(isSmallScreen);
  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        overflow: 'auto',
        backgroundColor: '#2db7db',
      }}
    >
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <img src={isSmallScreen ? mobile : tablet} style={{}} />
      </div>
    </div>
  );
}
