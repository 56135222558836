import Layout from '../Components/Layout/Layout';
import 'reactflow/dist/style.css';
import { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  useEdgesState,
} from 'reactflow';
import Button from '@mui/material/Button';

import MainNode from '../Components/MainScreen/MainNode';
import ChatNode from '../Components/MainScreen/ChatNode';
import ChatComponent from '../Components/MainScreen/Chat/ChatComponent';
import { newProjectSave, postConfig } from '../../api/workFlowApi';
import GlobalContext from '../../GlobalContext';
import NewMySource from '../Components/MainScreen/Sources/NewMySource';
import ChooseSources from '../Components/MainScreen/Sources/ChooseSources';
import EditMySource from '../Components/MainScreen/Sources/EditMySource';
import MyAccount from '../Components/MainScreen/MyAccount';
import SuccessAlert from '../Components/MainScreen/SuccessAlert';
import NewProject from '../Components/MainScreen/Project/NewProject';
import ChooseProject from '../Components/MainScreen/Project/ChooseProject';
import About from '../Components/MainScreen/About';
import LoadProject from '../Components/MainScreen/Project/LoadProject';
import CustomEdge from '../Components/MainScreen/CustomEdge';
import FirstModal from '../Components/FirstModal';
const initialEdges = [];
export default function MainScreen() {
  const [rfInstance, setRfInstance] = useState(null);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [i, setI] = useState(2);
  const [firstElement, setFirstElement] = useState('true');

  const [showChat, setShowChat] = useState(false);
  const { langSelected, GlobalActions, state } = useContext(GlobalContext);
  const [configResult, setConfigResult] = useState('');
  const [answerIa, setAnswerIa] = useState('');
  const [newSourceOpen, setnewSourceOpen] = useState(false);
  const [newSourceExist, setNewSourceExist] = useState(false);
  const [sourcesToChoose, setSourcesToChoose] = useState(false);
  const [sourceChanging, setSourcesChanging] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [sourceChangingData, setSourcesChangingData] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [headerText, setHeaderText] = useState('');
  const [newProjectOpen, setNewProjectOpen] = useState(false);
  const [newProjectExist, setNewProjectExist] = useState(false);
  const [projectToChoose, setProjectToChoose] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [projectLoadOpen, setProjectLoadOpen] = useState(false);
  const [projectName, setProjectName] = useState('New project');
  const sourceToChooseHandle = (val) => {
    setSourcesChangingData(val);
    setSourcesChanging(!sourceChanging);
  };

  let isChat = false;
  let chatInit = 0;
  const [test, setTest] = useState([]);
  const initialNodes = [
    {
      id: 'node-0',
      type: 'initial',
      position: {
        x: +500,
        y: 200,
      },
    },
  ];

  const [nodes, setNodes] = useState(initialNodes);

  const handleNodeDoubleClick = useCallback((nodeId) => {}, []);

  const nodeTypes = useMemo(
    () => ({
      custom: MainNode,
      initial: (props) => (
        <ChatNode
          {...props}
          onDoubleClick={() => handleNodeDoubleClick(props.id)}
        />
      ),
    }),
    [handleNodeDoubleClick]
  );
  const edgeTypes = useMemo(
    () => ({
      custom: CustomEdge,
    }),
    []
  );

  useEffect(() => {
    if (isChat) {
      setShowChat(true);
      isChat = false;
    }
  }, [isChat]);

  useEffect(() => {
    if (chatInit > 0) {
      setShowChat(!showChat);
      postConfig(configResult).then((val) => {
        setAnswerIa(val);
      });
    }
  }, [chatInit]);

  const putSelectedValue = async (
    name,
    description,
    sources,
    colors,
    custom
  ) => {
    /////////////////////////////////
    const newElement = {
      id: `node-${i}`,
      type: 'custom',
      position: {
        x: Math.floor(Math.random() * 200 + 200),
        y: Math.floor(Math.random() * 200 + 100),
      },
      data: {
        title: name,
        description: description,
        colors: colors,
        firstElement: firstElement,
        custom: custom,
      },
      sources: sources,
    };

    await setNodes([...nodes, newElement]);

    setI((currentI) => currentI + 1);

    GlobalActions.setNodesArray(JSON.stringify(nodes));
    setFirstElement(false);
  };

  const saveConfig = async (projectName) => {
    let flowToSet = {};

    if (rfInstance) {
      flowToSet = await rfInstance.toObject();
      flowToSet.projectName = projectName;
      flowToSet.chatFlow = JSON.stringify(state.chatFlow);
      flowToSet.redactorState = state.redactorState;
      flowToSet.config = state.config;
      newProjectSave(flowToSet);
      setProjectName(projectName);
    }
  };

  const downloadJsonFile = async () => {
    let flowToSet;
    if (rfInstance) {
      flowToSet = await rfInstance.toObject();
    }
    const jsonDataString = JSON.stringify(flowToSet, null, 2);
    const blob = new Blob([jsonDataString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'roadmovie.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const onNodesChange = useCallback(
    (changes) =>
      setNodes((nds) => {
        return applyNodeChanges(changes, nds);
      }),

    []
  );

  const onConnect = useCallback(
    async (params) => {
      let arr = [...edges, { source: params.source, target: params.target }];

      if (params.target === 'node-0') {
        const excludeNodeId = 'node-0';

        const uniqueNodeIds = [
          ...new Set(arr.flatMap((edge) => [edge.source, edge.target])),
        ].filter((id) => id !== excludeNodeId);

        let toSend = {};

        nodes.map((val, index) => {
          if (uniqueNodeIds.includes(val.id)) {
            let element = val.data.custom ? 'custom_tools' : 'tools';

            if (toSend.hasOwnProperty(element)) {
            } else {
              toSend[element] = {};
            }

            let sources = val.sources;
            if (Array.isArray(val.sources)) {
              sources = val.sources.reduce((acc, obj, index) => {
                if (obj.hasOwnProperty('pdf')) {
                  obj.custom = obj.pdf.fullPathFileUploaded.replace(
                    './chatbot/data/userfiles/',
                    ''
                  );
                  delete obj.pdf;
                }

                acc[index] = obj;

                return acc;
              }, {});
            }
        
         
            toSend[element][index] = {
              tool_name: val.data.description[langSelected === 1 ? 'es' : 'en'],
              sources: sources,
            };

            return val;
          }
        });

        GlobalActions.setConfig(toSend);
        setConfigResult(toSend);
        setShowChat(true);
        let res = await postConfig(toSend);
        setAnswerIa(res);
      } else {
        let headColor = nodes.find((val) => val.id === params.source)?.data
          .colors.color1;

        const newEdge = {
          ...params,
          type: 'custom',
          style: { stroke: headColor, strokeWidth: 2, zIndex: '999' },
        };
        setEdges((eds) => addEdge(newEdge, eds));
      }
    },
    [nodes, edges]
  );

  const handleKeyDown = (event) => {
    if (event.keyCode === 46) {
      let filteredEdges = edges.filter((element) => !element.selected);

      const filteredNodes = nodes.filter((element) => {
        if (!element.selected || element.id == 'node-0') {
          return element;
        } else {
          filteredEdges = edges.filter((el) => {
            if (
              element.id !== el.target &&
              element.id !== el.source &&
              el.target !== 'node-0'
            ) {
              return el;
            }
          });
        }
      });

      setNodes(filteredNodes);
      setEdges(filteredEdges);
      setConfigResult('');
      GlobalActions.setConfig('');
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [nodes, edges]);

  const closeModal = () => {
    setShowChat(false);
    setAnswerIa('');
    setnewSourceOpen(false);
  };

  const uploadJsonFile = (val) => {
    setProjectName(val.projectName);
    GlobalActions.setRedactorState(val.redactorState);
    GlobalActions.setConfig(val.config);
    GlobalActions.setChatFlow(JSON.parse(val.chatFlow));
    setConfigResult(val.config);

    setEdges(val.edges);
    setNodes(val.nodes);

    function findLatestNodeId(nodes) {
      const ids = nodes.map((node) =>
        parseInt(node.id.replace('node-', ''), 10)
      );

      const maxId = Math.max(...ids);

      return maxId;
    }

    setI(findLatestNodeId(val.nodes) + 1);
  };

  const newProject = () => {
    setEdges([]);
    setProjectName('New project');
    setNodes(initialNodes);
    setShowChat(false);
    setConfigResult('');
    GlobalActions.setConfig('');
    GlobalActions.setChatFlow('');
    GlobalActions.setRedactorState('');
  };
  const chooseSource = () => {
    setnewSourceOpen(true);
  };

  return (
    <Layout
      putSelectedValue={putSelectedValue}
      downloadJsonFile={downloadJsonFile}
      uploadJsonFile={uploadJsonFile}
      newProject={newProject}
      chooseSource={chooseSource}
      newSourceExist={newSourceExist}
      setSourcesToChoose={setSourcesToChoose}
      setShowAccount={setShowAccount}
      setAlertOpen={setAlertOpen}
      setHeaderText={setHeaderText}
      setAlertText={setAlertText}
      setNewProjectOpen={setNewProjectOpen}
      newProjectExist={newProjectExist}
      setProjectToChoose={setProjectToChoose}
      aboutOpen={aboutOpen}
      setAboutOpen={setAboutOpen}
      newProjectOpen={newProjectOpen}
      newSourceOpen={newSourceOpen}
      sourceChanging={sourceChanging}
      setProjectLoadOpen={setProjectLoadOpen}
      showChat={showChat}
    >
      <div className="main-div">
        <div className="project-name-main">
          <Button
            id="basic-button"
            aria-haspopup="true"
            style={{
              color: 'black',
              textTransform: 'none',
            }}
          >
            {projectName}
          </Button>
        </div>
        <div className="alert-box">
          <SuccessAlert
            alertOpen={alertOpen}
            setAlertOpen={setAlertOpen}
            alertText={alertText}
            headerText={headerText}
          />
        </div>
        {aboutOpen && (
          <About aboutOpen={aboutOpen} setAboutOpen={setAboutOpen} />
        )}

        {showAccount && (
          <MyAccount
            langSelected={langSelected}
            setShowAccount={setShowAccount}
            showAccount={showAccount}
          />
        )}

        {showChat && (
          <ChatComponent
            configResult={configResult}
            answerIa={answerIa}
            closeModal={closeModal}
          />
        )}
        {newProjectOpen && (
          <NewProject
            newProjectOpen={newProjectOpen}
            setNewProjectOpen={setNewProjectOpen}
            setAlertOpen={setAlertOpen}
            setHeaderText={setHeaderText}
            setAlertText={setAlertText}
            saveConfig={saveConfig}
            setNewProjectExist={setNewProjectExist}
            newProjectExist={newProjectExist}
          />
        )}
        {newSourceOpen && (
          <NewMySource
            closeModal={closeModal}
            newSourceExist={newSourceExist}
            setNewSourceExist={setNewSourceExist}
            setAlertOpen={setAlertOpen}
            setHeaderText={setHeaderText}
            setAlertText={setAlertText}
          />
        )}
        {sourcesToChoose && (
          <ChooseSources
            langSelected={langSelected}
            sourceToChooseHandle={sourceToChooseHandle}
            newSourceExist={newSourceExist}
            setSourcesToChoose={setSourcesToChoose}
            setNewSourceExist={setNewSourceExist}
            setAlertOpen={setAlertOpen}
            setHeaderText={setHeaderText}
            setAlertText={setAlertText}
          />
        )}

        {projectToChoose && (
          <ChooseProject
            setProjectToChoose={setProjectToChoose}
            projectToChoose={projectToChoose}
            setNewProjectExist={setNewProjectExist}
            setAlertOpen={setAlertOpen}
            setHeaderText={setHeaderText}
            setAlertText={setAlertText}
            langSelected={langSelected}
            newProjectExist={newProjectExist}
          />
        )}

        {projectLoadOpen && (
          <LoadProject
            setProjectToChoose={setProjectToChoose}
            projectToChoose={projectToChoose}
            setNewProjectExist={setNewProjectExist}
            setAlertOpen={setAlertOpen}
            setHeaderText={setHeaderText}
            setAlertText={setAlertText}
            langSelected={langSelected}
            newProjectExist={newProjectExist}
            projectLoadOpen={projectLoadOpen}
            setProjectLoadOpen={setProjectLoadOpen}
            uploadJsonFile={uploadJsonFile}
          />
        )}

        {sourceChanging && (
          <EditMySource
            sourcesChangingData={sourceChangingData}
            setSourcesChanging={setSourcesChanging}
            setNewSourceExist={setNewSourceExist}
            setAlertOpen={setAlertOpen}
            setHeaderText={setHeaderText}
            setAlertText={setAlertText}
          />
        )}
        <ReactFlow
          nodes={nodes}
          onNodesChange={onNodesChange}
          nodeTypes={nodeTypes}
          edges={edges}
          edgeTypes={{ custom: CustomEdge }}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onInit={setRfInstance}
          onNodeClick={handleNodeDoubleClick}
        >
          <Controls className="control" />
          <Background />
        </ReactFlow>
      </div>
    </Layout>
  );
}
