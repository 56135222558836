import React, { useState, useRef } from 'react';
import { vocabulary } from '../../../services/vocabulary';
import Button from '@mui/material/Button';
import cloudUpload from '../../../images/cloud-download.png';
import { postNewDocument } from '../../../api/workFlowApi';
export default function FileUpload({ handleFileUpload, lang, setFilesArray }) {
  const [isDragging, setIsDragging] = useState(false);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [filesToInput, setFilesToInput] = useState([]);

  const fileUpload = async (val) => {
    debugger;
    let fullPathFileUploaded = await postNewDocument(val);

    console.log(fullPathFileUploaded);
    handleFileUpload({
      fileName: val.name,
      fullPathFileUploaded: fullPathFileUploaded,
    });
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
    fileUpload(droppedFile);
  };

  const handleFileSelect = () => {
    setFile(fileInputRef.current.click());
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    fileUpload(selectedFile);
  };

  return (
    <div
      className="upload-drug-n-drop-div"
      style={{
        borderColor: isDragging ? '#007bff' : '#ccc',
      }}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <img
        src={cloudUpload}
        alt="upload image"
        className="logout-icon"
        width="8%"
      />

      <p>{vocabulary.dragNDropFileHere[lang]}</p>

      <input
        type="file"
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
        accept="application/pdf"
      />

      <Button
        variant="outlined"
        color="primary"
        size="small"
        style={{
          width: '25%',
          marginLeft: '20px',
          backgroundColor: '#5A58E6',
          color: 'white',
          textTransform: 'none',

          fontSize: '10px',
        }}
        fullWidth
        margin="normal"
        onClick={handleFileSelect}
      >
        {vocabulary.find[lang]}
      </Button>
    </div>
  );
}
