import React, { useState, useEffect, useContext, useRef } from 'react';
import { getDocuments } from '../../../../api/workFlowApi';
import fileIcon from '../../../../images/file-icon.png';
import MUIRichTextEditor from 'mui-rte';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { vocabulary } from '../../../../services/vocabulary';
import whiteBackIcon from '../../../../images/white-background-arrow.png';
import GlobalContext from '../../../../GlobalContext';
import ThumbnailPdf from './ThumbnailPdf';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function Editor({ langSelected }) {
  const [convertedText, setConvertedText] = useState('');

  const { GlobalActions, state } = useContext(GlobalContext);
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editorState, setEditorState] = useState('');
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoading(false);
  };

  const defaultTheme = createTheme({
    // Set up your custom MUI theme here
  });

  useEffect(() => {
    const savedContent = state.redactorState;

    if (savedContent !== null) {
      setConvertedText(savedContent);
    }
  }, []);

  useEffect(() => {
    const res = async () => {
      await GlobalActions.setRedactorState(convertedText);
    };

    res();
  }, [convertedText]);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],

      [{ list: 'bullet' }],

      [{ color: ['red', '#785412'] }],
      [{ background: ['red', '#785412'] }],
      [{ align: ['right', 'center', 'justify'] }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'blockquote',
    'list',
    'color',
    'background',
    'align',
    'size',
    'font',
  ];

  const inputRef = useRef(null);
  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="editor-main-div" onClick={focusInput}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h4 style={{ color: '#00173E' }}>{vocabulary.myNotes[langSelected]}</h4>
      </div>
      <ReactQuill
        theme="snow"
        value={convertedText}
        onChange={setConvertedText}
        style={{ minHeight: '300px' }}
        modules={modules}
        formats={formats}
        ref={inputRef}
      />
    </div>
  );
}
