import backIcon from '../../../../images/back-arrow.png';
import { vocabulary } from '../../../../services/vocabulary';
import TextField from '@mui/material/TextField';
import fileIcon from '../../../../images/file-icon.png';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState, useEffect, useContext, useRef } from 'react';
import Button from '@mui/material/Button';
import GlobalContext from '../../../../GlobalContext';
import FileUpload from '../FileUpload';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {
  changeSource,
  deleteDocument,
  newSource,
} from '../../../../api/workFlowApi';
import FileProperty from '../FileProperty';
import Box from '@mui/material/Box';
export default function EditMySource({
  sourcesChangingData,
  setAlertOpen,
  setHeaderText,
  setAlertText,
  setSourcesChanging,
}) {
  const { langSelected } = useContext(GlobalContext);
  const [projectName, setProjectName] = useState('');
  const [directorName, setDirectorName] = useState('');
  const [productorName, setProductorName] = useState('');
  const [year, setYear] = useState('');
  const [duration, setDuration] = useState('');
  const [genre, setGenre] = useState('');
  const [movieState, setMovieState] = useState('');
  const [logLine, setLogLine] = useState('');
  const [sinopsis, setSinopsis] = useState('');
  const [fileProperties, setFileProperties] = useState(false);
  const [filesToInput, setFilesToInput] = useState([]);
  const [fileToChange, setFileToChange] = useState('');
  useEffect(() => {
    let files = sourcesChangingData.files.map((val) => {
      return JSON.parse(val);
    });

    setFilesToInput(files);
    setProjectName(sourcesChangingData.projectName);
    setDirectorName(sourcesChangingData.directorName);
    setProductorName(sourcesChangingData.productorName);
    setYear(sourcesChangingData.year);
    setDuration(sourcesChangingData.duration);
    setGenre(sourcesChangingData.genre);
    setMovieState(sourcesChangingData.movieState);
    setLogLine(sourcesChangingData.logLine);
    setSinopsis(sourcesChangingData.sinopsis);
  }, []);

  const setShowFileProperty = (val) => {
    setFileToChange(val);
    setFileProperties(!fileProperties);

    console.log(fileProperties);
  };

  const handleFileUpload = (val) => {
    let arr = [...filesToInput];
    arr.push(val);
    setFilesToInput(arr);
  };
  const handleFileChange = (val) => {
    let arr = filesToInput.map((el) => {
      if (el.fullPathFileUploaded === val.fullPathFileUploaded) {
        return val;
      } else {
        return el;
      }
    });
    setAlertOpen(true);
    setHeaderText(vocabulary.fileDescription[langSelected]);
    setAlertText(vocabulary.fileDescriptionText[langSelected]);

    console.log(arr, 'array');
    setFilesToInput(arr);
  };

  const handleSubmit = async () => {
    let files = filesToInput.map((val) => {
      return JSON.stringify(val);
    });
    let data = {
      projectName: projectName,
      directorName: directorName,
      productorName: productorName,
      year: year,
      duration: duration,
      genre: genre,
      movieState: movieState,
      logLine: logLine,
      sinopsis: sinopsis,
      files: files,
      _id: sourcesChangingData._id,
    };

    setAlertOpen(true);
    setHeaderText(vocabulary.sourceUpdated[langSelected]);
    setAlertText(vocabulary.sourceUpdatedText[langSelected]);
    changeSource(data);
    setSourcesChanging(false);
  };
  const setFilesArray = (val) => {
    setFilesToInput(val);
  };

  const handleDelete = async (val) => {
    let arr = filesToInput.filter((el) => {
      if (el.fileName !== val.fileName) {
        return el;
      }
    });
    setHeaderText(vocabulary.fileDeleted[langSelected]);
    setAlertText(vocabulary.fileDeletedText[langSelected]);
    setAlertOpen(true);
    setFilesToInput(arr);
    deleteDocument(val.fullPathFileUploaded);
  };

  return (
    <div className="chat-div">
      <FileProperty
        langSelected={langSelected}
        fileProperties={fileProperties}
        setFileProperties={setFileProperties}
        fileToChange={fileToChange}
        handleFileChange={handleFileChange}
      />

      <div className="header-chat">
        <img
          src={backIcon}
          alt="back-icon"
          className="chat-icon-back"
          onClick={() => {
            setSourcesChanging(false);
          }}
        />
        <h2 className="my-project-name">
          {vocabulary.changeSource[langSelected]}
        </h2>
      </div>
      <div className="chat-main-block">
        <div className="chat-left-block">
          <form className="form-new-source" onSubmit={handleSubmit}>
            <div className="label-new-project">
              {vocabulary.projectName[langSelected]}
            </div>

            <TextField
              variant="outlined"
              autoComplete="project name"
              fullWidth
              margin="dense"
              placeholder={vocabulary.projectName[langSelected]}
              name="projectName"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              style={{ backgroundColor: 'white' }}
            />

            <div className="horizontal-block-new-project">
              <div className="name-block-new-source">
                <div className="label-new-project">
                  {vocabulary.directorName[langSelected]}
                </div>

                <TextField
                  variant="outlined"
                  autoComplete="director name"
                  fullWidth
                  margin="dense"
                  placeholder={vocabulary.directorName[langSelected]}
                  name="directorName"
                  value={directorName}
                  onChange={(e) => setDirectorName(e.target.value)}
                  style={{ backgroundColor: 'white' }}
                />
              </div>

              <div className="space-horizontal" />

              <div className="name-block-new-source">
                <div className="label-new-project">
                  {vocabulary.productorName[langSelected]}
                </div>

                <TextField
                  variant="outlined"
                  autoComplete="productor name"
                  fullWidth
                  margin="dense"
                  placeholder={vocabulary.productorName[langSelected]}
                  name="productorName"
                  value={productorName}
                  onChange={(e) => setProductorName(e.target.value)}
                  style={{ backgroundColor: 'white' }}
                />
              </div>
            </div>
            <div className="horizontal-block-new-project">
              <div className="horizontal-block-new-project">
                <div className="name-block-new-source">
                  <div className="label-new-project">
                    {vocabulary.year[langSelected]}
                  </div>

                  <TextField
                    variant="outlined"
                    autoComplete="year"
                    fullWidth
                    margin="dense"
                    placeholder={vocabulary.year[langSelected]}
                    name="year"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    style={{ backgroundColor: 'white' }}
                  />
                </div>

                <div className="space-horizontal" />

                <div className="name-block-new-source">
                  <div className="label-new-project">
                    {vocabulary.duration[langSelected]}
                  </div>

                  <TextField
                    variant="outlined"
                    autoComplete="duration"
                    fullWidth
                    margin="dense"
                    placeholder={vocabulary.duration[langSelected]}
                    name="duration"
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    style={{ backgroundColor: 'white' }}
                  />
                </div>
              </div>
              <div className="space-horizontal" />
              <div className="horizontal-block-new-project">
                <div className="name-block-new-source">
                  <div className="label-new-project">
                    {vocabulary.genre[langSelected]}
                  </div>
                  <Box sx={{ width: '100%' }}>
                    <FormControl
                      margin="dense"
                      sx={{ width: '100%', backgroundColor: 'white' }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={genre}
                        onChange={(e) => setGenre(e.target.value)}
                      >
                        <MenuItem value={'Terror'}>Terror</MenuItem>
                        <MenuItem value={'Romantic'}>Romantic</MenuItem>
                        <MenuItem value={'Fantasy'}>Fantasy</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="space-horizontal" />

                <div className="name-block-new-source">
                  <div className="label-new-project">
                    {vocabulary.state[langSelected]}
                  </div>
                  <Box sx={{ width: '100%' }}>
                    <FormControl
                      margin="dense"
                      sx={{ width: '100%', backgroundColor: 'white' }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={movieState}
                        onChange={(e) => setMovieState(e.target.value)}
                      >
                        <MenuItem value={'in filming'}>in filming</MenuItem>
                        <MenuItem value={'in productions'}>
                          in productions
                        </MenuItem>
                        <MenuItem value={'in distribution'}>
                          in distribution
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </div>
            </div>

            <div className="label-new-project">
              {vocabulary.logLine[langSelected]}
            </div>

            <TextField
              variant="outlined"
              autoComplete="logLine"
              fullWidth
              margin="dense"
              placeholder={vocabulary.logLine[langSelected]}
              name="logLine"
              value={logLine}
              onChange={(e) => setLogLine(e.target.value)}
              style={{ backgroundColor: 'white' }}
              multiline
              rows={3}
            />
            <div className="label-new-project">
              {vocabulary.sinopsis[langSelected]}
            </div>

            <TextField
              variant="outlined"
              autoComplete="sinopsis"
              fullWidth
              margin="dense"
              placeholder={vocabulary.sinopsis[langSelected]}
              name="sinopsis"
              value={sinopsis}
              onChange={(e) => setSinopsis(e.target.value)}
              style={{ backgroundColor: 'white' }}
              multiline
              rows={6}
            />

            <div className="button-new-block">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                style={{
                  width: '30%',
                  fontSize: '10px',
                  marginTop: '10px',
                  backgroundColor: '#5A58E6',
                  color: 'white',
                  textTransform: 'none',
                }}
                fullWidth
                margin="normal"
                onClick={handleSubmit}
              >
                {vocabulary.save[langSelected]}
              </Button>
            </div>
          </form>
        </div>
        <div className="chat-right-block">
          <div className="file-download-area">
            <FileUpload
              lang={langSelected}
              handleFileUpload={handleFileUpload}
              setFilesArray={setFilesArray}
            />
          </div>
          <div className="file-download-area">
            {filesToInput.map((val) => {
              return (
                <div key={val.fileName} className="file-sign">
                  <div
                    className="delete-icon"
                    onClick={() => {
                      handleDelete(val);
                    }}
                  >
                    X
                  </div>
                  <img
                    src={fileIcon}
                    alt="back-icon"
                    className="file-icon"
                    onClick={() => {
                      setShowFileProperty(val);
                    }}
                  />

                  <div className="filename-div">{val.fileName}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
