import React from 'react';
import {
  getBezierPath,
  getEdgeCenter,
  getMarkerEnd,
} from 'react-flow-renderer';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = { zIndex: '10000' },
  markerEnd,
}) {
  const edgePath = getBezierPath({
    sourceX: sourceX + 5,
    sourceY, // Offset by 5px
    sourcePosition,
    targetX: targetX - 5,
    targetY, // Offset by 5px
    targetPosition,
  });

  const markerEndId = getMarkerEnd(markerEnd, id);

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEndId}
      />
    </>
  );
}
