import { useContext, useState } from 'react';
import GlobalContext from '../../../GlobalContext';
import logoutIcon from '../../../images/exit-black.png';
import userIcon from '../../../images/person-icon.png';
import { useNavigate } from 'react-router-dom';
import logo from '../../../images/logo.png';
import MySources from './MenuComponents/MySources';
import MyProjects from './MenuComponents/MyProjects';
import Sources from './MenuComponents/Sources';
import { vocabulary } from '../../../services/vocabulary';
import Button from '@mui/material/Button';

export default function HeaderMenu({
  putSelectedValue,
  downloadJsonFile,
  uploadJsonFile,
  newProject,
  chooseSource,
  newSourceExist,
  setSourcesToChoose,
  setShowAccount,
  setAlertOpen,
  setHeaderText,
  setAlertText,
  setNewProjectOpen,
  newProjectExist,
  setProjectToChoose,
  aboutOpen,
  setAboutOpen,
  newProjectOpen,
  newSourceOpen,
  sourceChanging,
  setProjectLoadOpen,
  showChat,
}) {
  const [lang, setLang] = useState(navigator.language !== 'es' ? 0 : 1);

  const { GlobalActions } = useContext(GlobalContext);

  const navigate = useNavigate();

  const handleExit = () => {
    GlobalActions.logOut();
    navigate('/');
  };

  const handleLangChange = () => {
    if (lang === 0) {
      GlobalActions.setLang(1);
      setLang(1);
    } else {
      GlobalActions.setLang(0);
      setLang(0);
    }
  };

  const myAccount = () => {
    setShowAccount(true);
  };
  const handleClick = () => {};

  return (
    <div className="header">
      <div className="space-logo" />
      <div className="logo-corner">
        <img src={logo} alt="user image" width="100vh" />
      </div>
      <div className="space-logo" />
      <div className="menu-block">
        <Button
          id="basic-button"
          aria-haspopup="true"
          onClick={() => {
            setAboutOpen(!aboutOpen);
          }}
          style={{
            color: 'black',
            textTransform: 'none',
            fontSize: '16px',
            margin: 'none',
          }}
        >
          {vocabulary.about[lang]}
        </Button>

        <div className="space-menu" />
        {!showChat && (
          <MyProjects
            lang={lang}
            downloadJsonFile={downloadJsonFile}
            uploadJsonFile={uploadJsonFile}
            newProject={newProject}
            setAlertOpen={setAlertOpen}
            setHeaderText={setHeaderText}
            setAlertText={setAlertText}
            setNewProjectOpen={setNewProjectOpen}
            newProjectExist={newProjectExist}
            setProjectToChoose={setProjectToChoose}
            setProjectLoadOpen={setProjectLoadOpen}
          />
        )}
        {!newProjectOpen & !newSourceOpen & !sourceChanging & !showChat ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div className="space-menu" />
            <MySources
              lang={lang}
              chooseSource={chooseSource}
              putSelectedValue={putSelectedValue}
              newSourceExist={newSourceExist}
              setSourcesToChoose={setSourcesToChoose}
            />
            <div className="space-menu" />
            <Sources lang={lang} putSelectedValue={putSelectedValue} />
          </div>
        ) : null}
      </div>

      <div className="exit-block">
        <div className="space-menu" />

        <div className="logout-icon">
          <div>
            {/*  <img
              src={userIcon}
              alt="user image"
              style={{ marginRight: '10px' }}
              width="20px"
              height="20px"
            />
  */}
          </div>

          <div className="space-menu" />

          <div className="lang-selector" onClick={handleLangChange}>
            {lang === 0 ? 'Español' : 'English'}
          </div>

          <div className="space-menu" />

          <div
            style={{
              cursor: 'pointer',

              fontWeight: 'bolder',
            }}
            onClick={myAccount}
          >
            {vocabulary.myAccount[lang]}
          </div>

          <div>
            {/*   <img
              src={logoutIcon}
              alt="user image"
              style={{ marginRight: '10px', marginLeft: '10px' }}
              width="30px"
              height="30px"
            />
  */}
          </div>
          <div className="space-menu" />
          <div style={{ cursor: 'pointer' }} onClick={handleExit}>
            {vocabulary.exit[lang]}
          </div>
        </div>
      </div>
    </div>
  );
}
