import { useContext, useEffect, useState } from 'react';
import logo from '../../../images/logo.png';

import LeftMenu from './LeftMenu';
import HeaderMenu from './HeaderMenu';
import GlobalContext from '../../../GlobalContext';

export default function Layout({
  children,
  putSelectedValue,
  downloadJsonFile,
  uploadJsonFile,
  newProject,
  chooseSource,
  newSourceExist,
  setSourcesToChoose,
  setShowAccount,
  setAlertOpen,
  setHeaderText,
  setAlertText,
  setNewProjectOpen,
  newProjectExist,
  setProjectToChoose,
  aboutOpen,
  setAboutOpen,
  newProjectOpen,
  newSourceOpen,
  sourceChanging,
  setProjectLoadOpen,
  showChat,
}) {
  const { menuMovieSelected, GlobalActions, langSelected } =
    useContext(GlobalContext);

  return (
    <div className="row-layout">
      <div className="header-div">
        <HeaderMenu
          putSelectedValue={putSelectedValue}
          downloadJsonFile={downloadJsonFile}
          uploadJsonFile={uploadJsonFile}
          newProject={newProject}
          chooseSource={chooseSource}
          newSourceExist={newSourceExist}
          setSourcesToChoose={setSourcesToChoose}
          setShowAccount={setShowAccount}
          setAlertOpen={setAlertOpen}
          setHeaderText={setHeaderText}
          setAlertText={setAlertText}
          setNewProjectOpen={setNewProjectOpen}
          newProjectExist={newProjectExist}
          setProjectToChoose={setProjectToChoose}
          aboutOpen={aboutOpen}
          setAboutOpen={setAboutOpen}
          newProjectOpen={newProjectOpen}
          newSourceOpen={newSourceOpen}
          sourceChanging={sourceChanging}
          setProjectLoadOpen={setProjectLoadOpen}
          showChat={showChat}
        />
      </div>
      <div className="right-part-down">{children}</div>
    </div>
  );
}
