import * as React from 'react';
import backIcon from '../../../../images/back-arrow.png';
import logoIcon from '../../../../images/logo.png';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { vocabulary } from '../../../../services/vocabulary';
import {
  deleteProject,
  deleteSource,
  getProject,
  getSource,
} from '../../../../api/workFlowApi';
import DeleteProject from './DeleteProject';
import EditProject from './EditProject';
import formatDate from '../../../../helpers/formatDate';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function LoadProject({
  setProjectToChoose,
  projectToChoose,
  setNewProjectExist,
  langSelected,
  setAlertOpen,
  setHeaderText,
  setAlertText,
  newProjectExist,
  projectLoadOpen,
  setProjectLoadOpen,
  uploadJsonFile,
}) {
  const [sourcesArray, setSourcesArray] = React.useState([]);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [guide, setGuide] = React.useState('');
  const [fileName, setFileName] = React.useState('');
  const [valToDelete, setValToDelete] = React.useState('');
  const [newProjectOpen, setNewProjectOpen] = React.useState(false);
  const [projectToChange, setProjectToChange] = React.useState('');
  React.useEffect(() => {
    let res = async () => {
      let result = await getProject();
      setSourcesArray(result);
    };

    res();
  }, [newProjectExist]);

  const handleDelete = async () => {
    await deleteProject({ _id: valToDelete._id });
    setAlertOpen(true);
    setHeaderText(vocabulary.sourceDeleted[langSelected]);
    setAlertText(vocabulary.sourceDeletedText[langSelected]);
    setNewProjectExist(!newProjectExist);
    handleClose();
  };

  const handleClose = () => {
    setProjectLoadOpen(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={projectLoadOpen}
        sx={{
          '& .MuiDialog-paper': {
            position: 'fixed',
            right: -32,
            top: -32,
            bottom: 0,
            height: '100%',
            maxHeight: '100%',
            overflowY: 'auto',
          },
        }}
      >
        <div className="header-chat">
          <DeleteProject
            deleteOpen={deleteOpen}
            setDeleteOpen={setDeleteOpen}
            handleDelete={handleDelete}
            handleClose={handleClose}
            setAlertOpen={setAlertOpen}
            setHeaderText={setHeaderText}
            setAlertText={setAlertText}
          />
          <img
            src={backIcon}
            alt="back-icon"
            className="chat-icon-back"
            onClick={handleClose}
          />
          <h4 className="my-project-name">
            {vocabulary.projectList[langSelected]}
          </h4>
        </div>

        <DialogContent
          sx={{
            // Target the dialog paper element
            // Add scroll for overflow content
            overflowX: 'hidden',
          }}
          dividers
        >
          <div className="sources-div">
            {sourcesArray.map((val) => {
              return (
                <div className="items-source" key={val._id}>
                  <EditProject
                    setAlertOpen={setAlertOpen}
                    setHeaderText={setHeaderText}
                    setAlertText={setAlertText}
                    projectToChange={projectToChange}
                    newProjectExist={newProjectExist}
                    setNewProjectExist={setNewProjectExist}
                    projectToChoose={projectToChoose}
                    setProjectToChoose={setProjectToChoose}
                    newProjectOpen={newProjectOpen}
                    setNewProjectOpen={setNewProjectOpen}
                  />
                  <img
                    src={logoIcon}
                    alt="back-icon"
                    onClick={handleClose}
                    width="50px"
                    height="25px"
                  />

                  <div className="name-source">
                    <div className="name-source-header">
                      {val.projectName.slice(0, 25)}
                    </div>

                    <div className="description-source">
                      {vocabulary.created[langSelected]}
                      {formatDate(val.createdAt)}
                    </div>
                  </div>

                  <div className="button-div">
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      style={{
                        fontSize: '8px',
                        marginTop: '10px',
                        backgroundColor: '#5A58E6',
                        color: 'white',
                        textTransform: 'none',
                      }}
                      fullWidth
                      margin="normal"
                      onClick={() => {
                        uploadJsonFile(JSON.parse(val.data));

                        handleClose();
                      }}
                    >
                      {vocabulary.loadProject[langSelected]}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
