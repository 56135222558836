import RoutesArtefacto from './Routes';
import { GlobalContextWrapper } from './GlobalContext';
import { useMediaQuery } from 'react-responsive';
import SmallScreen from './Pages/PublicPages/SmallScreen';
import { GoogleOAuthProvider } from '@react-oauth/google';
import MobileScreen from './Pages/PublicPages/MobileScreen';

function App() {
  const isSmallScreen = useMediaQuery({ maxWidth: 1200 });

  return (
    <div className="App">
      {!isSmallScreen ? (
        <div style={{ width: '100%', height: '100%' }}>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE}>
            <GlobalContextWrapper>
              <RoutesArtefacto />
            </GlobalContextWrapper>
          </GoogleOAuthProvider>
        </div>
      ) : (
        <GlobalContextWrapper>
          <MobileScreen />
        </GlobalContextWrapper>
      )}
    </div>
  );
}

export default App;
