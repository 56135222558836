import React, { useState, useEffect, useContext } from 'react';
import { getDocuments } from '../../../../api/workFlowApi';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { vocabulary } from '../../../../services/vocabulary';
import whiteBackIcon from '../../../../images/white-background-arrow.png';
import GlobalContext from '../../../../GlobalContext';
import ThumbnailPdf from './ThumbnailPdf';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';

export default function PdfPreview({
  configResult,
  langSelected,
  filePath,
  setIsDoc,
  currentPage,
  searchString,
}) {
  const { GlobalActions, state } = useContext(GlobalContext);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfFilePath, setPdfFilePath] = useState('');
  const [downloadUrl, setDownloadUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [isEditor, setIsEditor] = useState(true);
  const [pdfToShow, setPdfToShow] = useState('');

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoading(false);
  };

  useEffect(() => {
    const regex = /\/data\/(.*)/;
    const match = filePath.match(regex);
    const extractedString = match[1];
    setPdfToShow(extractedString);
    if (pdfToShow) {
      const fetchPdf = async () => {
        try {
          setLoading(true);

          const response = await getDocuments(pdfToShow);

          const blob = new Blob([response], { type: 'application/pdf' });

          const url = URL.createObjectURL(blob);
          setPdfFilePath(url);
          setDownloadUrl(url);
        } catch (error) {
          console.error('Error fetching PDF:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchPdf();

      return () => {
        if (pdfFilePath) {
          URL.revokeObjectURL(pdfFilePath);
        }
        if (downloadUrl) {
          URL.revokeObjectURL(downloadUrl);
        }
      };
    }
  }, [pdfToShow]);

  const goToPage = (event) => {
    const page = parseInt(event.target.value, 10);
    if (page >= 1 && page <= numPages) {
      setPageNumber(page);
    }
  };

  return (
    <div className="pdf-viewer-screen">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: '10px',
          marginRight: '10px',
          marginTop: '10px',
        }}
      >
        <img
          src={whiteBackIcon}
          alt="back-icon"
          width="30px"
          height="30px"
          className="chat-icon-back"
          onClick={() => {
            setIsDoc(false);
          }}
        />
        <h2 className="my-project-name" style={{}}>
          {vocabulary.sources[langSelected]}
        </h2>
      </div>

      {pdfFilePath && !loading && (
        <ThumbnailPdf
          file={pdfFilePath}
          currentPage={currentPage}
          searchString={searchString}
        />
      )}

      <div className="button-preview">
        {!loading && numPages && (
          <>
            <div onClick={() => setPageNumber((page) => Math.max(page - 1, 1))}>
              ⬅
            </div>

            <input
              className="pages-input"
              type="number"
              value={pageNumber}
              onChange={goToPage}
              min={1}
              max={numPages || 1}
            />
            <div
              onClick={() =>
                setPageNumber((page) => Math.min(page + 1, numPages))
              }
            >
              ➡
            </div>
          </>
        )}
      </div>

      {/* <div className="files-list">
        {Object.keys(configResult).map((key) => {
          const nestedObject = configResult[key];
          return Object.keys(nestedObject).map((nestedKey, index) => {
            return (
              <div>
                {Object.keys(nestedObject[nestedKey]['sources']).map(
                  (data, index) => {
                    console.log(
                      nestedObject[nestedKey]['sources'][data],
                      'final data'
                    );

                    return (
                      <div
                        key={nestedKey + index}
                        className="files-list"
                        onClick={() => {
                          if (
                            nestedObject[nestedKey]['sources'][
                              data
                            ].pdf.hasOwnProperty('fullPathFileUploaded')
                          ) {
                            let dir =
                              nestedObject[nestedKey]['sources'][data].pdf
                                .fullPathFileUploaded;
                            console.log(dir, 'direction');
                            setPdfToShow(
                              dir.replace(/^\.\/chatbot\/data\//, '')
                            );
                          } else {
                            let dir =
                              'pdf/' +
                              nestedObject[nestedKey]['sources'][data].pdf;
                            setPdfToShow(dir);
                          }
                          setIsEditor(false);
                        }}
                      >
                        <div className="pdf-block">
                          <img src={fileIcon} width="40px" />
                          {nestedObject[nestedKey]['sources'][
                            data
                          ].description.slice(0, 20)}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            );
          });
        })}
      </div> */}

      {numPages && (
        <div className="page-numbers">
          Page {pageNumber} of {numPages}{' '}
        </div>
      )}
    </div>
  );
}
