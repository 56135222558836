import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../GlobalContext';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import { passwordRecovery } from '../../api/authApi';
import backIcon from '../../images/back-arrow.png';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import logo from '../../images/logo.png';
import { vocabulary } from '../../services/vocabulary';
export default function PasswordRecoveryScreen() {
  const { isLoggedIn } = useContext(GlobalContext);
  const [email, setEmail] = useState('');
  const { state, GlobalActions, langSelected } = useContext(GlobalContext);
  const [showloginError, setShowLoginError] = useState(false);
  const [password, setUserPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res = passwordRecovery({ email: email });

    if (res === 'bad credentials') {
      setShowLoginError(true);
    } else {
      navigateBack();
    }
  };

  const navigateBack = () => {
    navigate('/');
  };
  return (
    <div className="login-screen">
      <div className="image-login" />

      <div className="login-container">
        <div className="login-box">
          <form className="form-login" onSubmit={handleSubmit}>
            <img src={logo} alt="Logo" width="150px" />
            <div className="form-group">
              <div className="input-div">
                <div className="login-header">
                  {vocabulary.reestablishPassword[langSelected]}
                </div>
              </div>
              <label htmlFor="e-mail"></label>
              <div className="input-div">
                <TextField
                  variant="outlined"
                  autoComplete="email"
                  fullWidth
                  margin="dense"
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setShowLoginError(false);

                    setEmail(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="input-div-under">
                <div className="not-have-an-account" onClick={navigateBack}>
                  {vocabulary.haveAccount[langSelected]}
                </div>
              </div>

              {showloginError && (
                <div className="error-message">{'Bad e-mail '}</div>
              )}

              <div className="input-div">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    width: '100%',
                    height: '40px',
                    marginTop: '5px',
                    backgroundColor: '#5A58E6',
                    color: 'white',
                    textTransform: 'none',
                  }}
                  fullWidth
                  margin="dense"
                  onClick={handleSubmit}
                >
                  Restablecer
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
