import * as React from 'react';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { vocabulary } from '../../../services/vocabulary';
import GlobalContext from '../../../GlobalContext';
import AboutVideoComponentEn from '../../PublicPages/AboutVideoComponentEn';
import AboutVideoComponentEs from '../../PublicPages/AboutVideoComponentEs';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function About({ aboutOpen, setAboutOpen }) {
  const { langSelected } = React.useContext(GlobalContext);

  const handleCloseOpen = () => {
    setAboutOpen(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleCloseOpen}
        aria-labelledby="customized-dialog-title"
        open={aboutOpen}
        maxWidth="md"
        sx={{
          minWidth: '500px',
          borderRadius: '10px',
          '& .MuiDialog-paper': {
            borderRadius: '10px',
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <div className="div-conditions">
            {vocabulary.conditions[langSelected]}
          </div>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseOpen}
          sx={{
            position: 'absolute',
            right: 26,
            top: 30,
            color: 'white',
            backgroundColor: '#5F5DF3',
            width: '20px',
            height: '20px',
            '&:hover': {
              backgroundColor: 'rgba(95, 93, 243, 0.8)',
            },
          }}
        >
          <CloseIcon
            sx={{
              width: '15px',
              height: '15px',
            }}
          />
        </IconButton>
        <DialogContent
          dividers
          className="custom-scrollbar"
          sx={{
            marginLeft: '30px',
            marginRight: '30px',
            maxHeight: '400px',
          }}
        >
          {langSelected !== 0 ? (
            <div className="conditions-text">
              <AboutVideoComponentEs width={'560'} height={'315'} />
              <h4>1. Introducción</h4>
              Bienvenido a RoadMovie, una aplicación proporcionada por ARTEFACTO
              FÍLMICO SL B44983641. Al acceder o utilizar RoadMovie, usted
              acepta estar vinculado por estos Términos y Condiciones y nuestra
              Política de Privacidad.
              <h4>2. Privacidad y Protección de Datos</h4>
              ARTEFACTO FÍLMICO SL B44983641 respeta su privacidad y se
              compromete a proteger sus datos personales. Los datos recogidos a
              través de RoadMovie se utilizan exclusivamente para proporcionar y
              mejorar nuestros servicios. No compartiremos su información con
              terceros sin su consentimiento, salvo en los casos exigidos por
              ley o para proteger nuestros derechos.
              <h4>3. Derechos de Propiedad Intelectual</h4>
              El contenido de RoadMovie, incluyendo textos, gráficos, logos, y
              software, es propiedad de ARTEFACTO FÍLMICO SL B44983641 o se
              utiliza bajo licencia. Usted se compromete a no copiar, modificar,
              distribuir o usar este contenido sin nuestro consentimiento
              expreso.
              <h4>4. Uso Aceptable</h4>
              Usted se compromete a no utilizar RoadMovie de manera que cause
              daño, sea ilegal, fraudulenta o perjudicial, o en conexión con
              cualquier propósito o actividad ilegal, fraudulenta o perjudicial.
              <h4>5. Limitación de Responsabilidad</h4>
              ARTEFACTO FÍLMICO SL B44983641 no será responsable de cualquier
              daño indirecto, incidental, especial o consecuente que surja del
              uso o la incapacidad de usar RoadMovie.
              <h4>6. Modificaciones a los Términos</h4>
              Nos reservamos el derecho de modificar estos Términos y
              Condiciones en cualquier momento. Los cambios serán efectivos una
              vez publicados en RoadMovie.
              <h4>7. Legislación Aplicable</h4>
              Estos Términos se regirán e interpretarán de acuerdo con las leyes
              de España, y cualquier disputa relacionada estará sujeta a la
              jurisdicción exclusiva de los tribunales españoles.
            </div>
          ) : (
            <div className="conditions-text">
              <AboutVideoComponentEn width={'560'} height={'315'} />
              <h4>1. Introduction</h4>
              Welcome to RoadMovie, an application provided by ARTEFACTO FÍLMICO
              SL B44983641. By accessing or using RoadMovie, you agree to be
              bound by these Terms and Conditions and our Privacy Policy.
              <h4>2. Privacy and Data Protection</h4>
              ARTEFACTO FÍLMICO SL B44983641 respects your privacy and is
              committed to protecting your personal data. The data collected
              through RoadMovie is used exclusively to provide and improve our
              services. We will not share your information with third parties
              without your consent, except in cases required by law or to
              protect our rights.
              <h4>3. Intellectual Property Rights</h4>
              The content of RoadMovie, including texts, graphics, logos, and
              software, is the property of ARTEFACTO FÍLMICO SL B44983641 or is
              used under license. You agree not to copy, modify, distribute, or
              use this content without our express consent.
              <h4>4. Acceptable Use</h4>
              You agree not to use RoadMovie in a way that causes harm, is
              illegal, fraudulent, or harmful, or in connection with any
              illegal, fraudulent, or harmful purpose or activity.
              <h4>5. Limitation of Liability</h4>
              ARTEFACTO FÍLMICO SL B44983641 will not be liable for any
              indirect, incidental, special, or consequential damages arising
              from the use or inability to use RoadMovie.
              <h4>6. Modifications to the Terms</h4>
              We reserve the right to modify these Terms and Conditions at any
              time. Changes will be effective once published on RoadMovie.
              <h4>7. Applicable Legislation</h4>
              These Terms will be governed and interpreted in accordance with
              the laws of Spain, and any related dispute will be subject to the
              exclusive jurisdiction of the Spanish courts.
            </div>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            margin: '20px',
          }}
        >
          {/* <Button
            variant="outlined"
            color="primary"
            size="small"
            style={{
              width: '10%',
              fontSize: '10px',

              backgroundColor: '#5A58E6',
              color: 'white',
              marginTop: 'auto',
              textTransform: 'none',

              marginBottom: 'auto',
            }}
            fullWidth
            margin="normal"
            onClick={handleCloseOpen}
          >
            {vocabulary.understand[langSelected]}
          </Button>*/}
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
