import logoIcon from '../../images/logo.png';
import load from '../../images/load.gif';

export default function SplashScreen() {
  return (
    <div className="splash">
      <div className="splash-image-block">
        <img src={logoIcon} alt="message-icon" />
      </div>
      <img width="100px" src={load} alt="splash gif" />

      <div className="splash-text">
        Derechos reservados a Artefacto film @2023
      </div>
    </div>
  );
}
