import * as React from 'react';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import recycleBin from '../../../images/recycle-bin.png';
import TextField from '@mui/material/TextField';
import { vocabulary } from '../../../services/vocabulary';
import GlobalContext from '../../../GlobalContext';
import { deleteUser } from '../../../api/workFlowApi';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DeleteAccount({
  deleteOpen,
  setDeleteOpen,
  handleDelete,
  handleClose,
}) {
  const { GlobalActions, langSelected } = React.useContext(GlobalContext);

  const handleCloseDelete = () => {
    setDeleteOpen(false);
  };

  const handleClick = async () => {
    await deleteUser();
    GlobalActions.logOut();
    handleCloseDelete();
    handleClose();
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={deleteOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {vocabulary.deleteSource[langSelected]}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDelete}
          sx={{
            position: 'absolute',
            right: 8,
            top: 20,
            color: 'white',
            backgroundColor: '#5F5DF3',
            width: '20px',
            height: '20px',
            '&:hover': {
              backgroundColor: 'rgba(95, 93, 243, 0.8)',
            },
          }}
        >
          <CloseIcon
            sx={{
              width: '15px',
              height: '15px',
            }}
          />
        </IconButton>
        <DialogContent dividers>
          <div className="file-properties-main">
            <div className="recycle-bin-view">
              <img src={recycleBin} alt="back-icon" width="40px" />
            </div>
            <div className="warning-div">
              <h4> {vocabulary.areYouSureToDeleteAccount[langSelected]}</h4>
              <p> {vocabulary.deleteTextAccount[langSelected]}</p>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            margin: '20px',
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            style={{
              width: '40%',
              fontSize: '10px',
              backgroundColor: ' #EE4037',
              color: 'white',
              marginTop: 'auto',
              marginBottom: 'auto',
              marginRight: '10%',
              textTransform: 'none',
            }}
            fullWidth
            margin="normal"
            onClick={handleClick}
          >
            {vocabulary.deleteAccount[langSelected]}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            style={{
              width: '40%',
              fontSize: '10px',
              textTransform: 'none',

              backgroundColor: '#5A58E6',
              color: 'white',
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
            fullWidth
            margin="normal"
            onClick={handleCloseDelete}
          >
            {vocabulary.cancel[langSelected]}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
