import React, { useState, useEffect, useContext } from 'react';
import { getDocuments } from '../../../../api/workFlowApi';
import fileIcon from '../../../../images/file-icon.png';
import MUIRichTextEditor from 'mui-rte';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { vocabulary } from '../../../../services/vocabulary';
import whiteBackIcon from '../../../../images/white-background-arrow.png';
import GlobalContext from '../../../../GlobalContext';
import ThumbnailPdf from './ThumbnailPdf';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';
import Editor from './Editor';
import PdfPreview from './PdfPreview';

export default function RightSideScreen({ configResult, langSelected }) {
  const { GlobalActions, state } = useContext(GlobalContext);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfFilePath, setPdfFilePath] = useState('');
  const [downloadUrl, setDownloadUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [isEditor, setIsEditor] = useState(true);
  const [pdfToShow, setPdfToShow] = useState(undefined);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoading(false);
  };

  const defaultTheme = createTheme({
    // Set up your custom MUI theme here
  });

  Object.assign(defaultTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          margin: 'auto',
          width: '95%',
          backgroundColor: 'white',
          height: '90%',
          overflow: 'auto',
        },
        container: {
          margin: 0,
        },
        editor: {
          marginLeft: '10px',
          marginRight: '10px',
        },
        toolbar: {
          position: 'sticky',
          top: 0,
          zIndex: 5,
          backgroundColor: '#fafafa',
          borderBottom: '2px solid #cccccc',
        },
      },
    },
  });

  const editorTextOnChange = async (e) => {
    let content = await e.getCurrentContent();

    content = convertToRaw(content);

    GlobalActions.setRedactorState(JSON.stringify(content));
  };

  const [editorState, setEditorState] = useState(null);

  useEffect(() => {
    if (pdfToShow) {
      const fetchPdf = async () => {
        try {
          setLoading(true);

          const response = await getDocuments(pdfToShow);

          const blob = new Blob([response], { type: 'application/pdf' });

          const url = URL.createObjectURL(blob);
          setPdfFilePath(url);
          setDownloadUrl(url);
        } catch (error) {
          console.error('Error fetching PDF:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchPdf();

      return () => {
        if (pdfFilePath) {
          URL.revokeObjectURL(pdfFilePath);
        }
        if (downloadUrl) {
          URL.revokeObjectURL(downloadUrl);
        }
      };
    }
  }, [pdfToShow]);

  const goToPage = (event) => {
    const page = parseInt(event.target.value, 10);
    if (page >= 1 && page <= numPages) {
      setPageNumber(page);
    }
  };

  return (
    <div className="pdf-viewer-screen">
      {isEditor ? (
        <Editor langSelected={langSelected} />
      ) : (
        <PdfPreview langSelected={langSelected} />
      )}
    </div>
  );
}
