import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { vocabulary } from '../../services/vocabulary';
import GlobalContext from '../../GlobalContext';
import AboutVideoComponentEn from '../PublicPages/AboutVideoComponentEn';
import AboutVideoComponentEs from '../PublicPages/AboutVideoComponentEs';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(0),
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: -35,
  color: 'white',
  backgroundColor: '#5F5DF3',
  width: '20px',
  height: '20px',
  '&:hover': {
    backgroundColor: 'rgba(95, 93, 243, 0.8)',
  },
}));

export default function FirstModal({ aboutOpen, setAboutOpen }) {
  const { langSelected } = React.useContext(GlobalContext);

  const handleCloseOpen = () => {
    setAboutOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleCloseOpen}
        aria-labelledby="customized-dialog-title"
        open={aboutOpen}
        maxWidth="md"
        sx={{
          minWidth: '900px',
          borderRadius: '10px',
          '& .MuiDialog-paper': {
            overflow: 'visible',
            position: 'relative',
          },
        }}
      >
        <CloseButton aria-label="close" onClick={handleCloseOpen}>
          <CloseIcon
            sx={{
              width: '10px',
              height: '10px',
            }}
          />
        </CloseButton>

        <DialogContent
          sx={{
            maxHeight: '600px',
            border: 'none',
          }}
        >
          {langSelected !== 0 ? (
            <AboutVideoComponentEs width={'840'} height={'473'} />
          ) : (
            <AboutVideoComponentEn width={'840'} height={'473'} />
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
