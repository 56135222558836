export default function formatDate(dateString) {
  const date = new Date(dateString);

  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString(); // Month is 0-indexed
  let year = date.getFullYear().toString().substr(-2); // Get last two digits of year

  // Add leading zeros if day or month is less than 10
  day = day.length < 2 ? '0' + day : day;
  month = month.length < 2 ? '0' + month : month;

  return `${day}/${month}/${year}`;
}
