import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { vocabulary } from '../../../../services/vocabulary';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getSource } from '../../../../api/workFlowApi';

export default function MySources({
  lang,
  chooseSource,
  putSelectedValue,
  newSourceExist,
  setSourcesToChoose,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [sourcesArray, setSourcesArray] = useState([]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setChooseSource = () => {
    chooseSource();
    handleClose();
  };

  useEffect(() => {
    let res = async () => {
      let result = await getSource();
      setSourcesArray(result);
    };

    res();
  }, []);

  useEffect(() => {
    let res = async () => {
      let result = await getSource();
      setSourcesArray(result);
    };

    res();
  }, [newSourceExist]);

  const handleClickCustomMenu = (val) => {
    let sources = [];
    val.files.map((val, index) => {
      let data = JSON.parse(val);

      sources[index] = {
        description: data.guide ? data.guide : 'where is no description',
        pdf: data.fullPathFileUploaded,
      };
    });

    let dataName = { es: val.projectName, en: val.projectName };
    let dataDescription = { es: val.sinopsis, en: val.sinopsis };
    let colors = { color1: '#134F5F', color2: '#2BAED3' };
    let custom = true;
   

    putSelectedValue(dataName, dataDescription, sources, colors, custom);
    handleClose();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          color: 'black',
          textTransform: 'none',
          whiteSpace: 'nowrap',
          fontSize: '16px',
          paddingRight: '0px',
        }}
      >
        {vocabulary.mySources[lang]}
        <KeyboardArrowDownIcon
          style={{
            fontSize: 'medium',
          }}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          style={{
            color: 'black',
            textTransform: 'none',
            fontSize: '16px',
          }}
          onClick={setChooseSource}
        >
          {vocabulary.newSource[lang]}
        </MenuItem>
        <MenuItem
          style={{
            color: 'black',
            textTransform: 'none',
            fontSize: '16px',
          }}
          onClick={() => {
            setSourcesToChoose(true);

            handleClose();
          }}
        >
          {vocabulary.editSource[lang]}
        </MenuItem>

        {sourcesArray?.length > 0 && (
          <div className="menu-line">{vocabulary.recentSources[lang]}</div>
        )}

        {sourcesArray?.map((val) => {
          return (
            <MenuItem
              style={{
                color: 'black',
                textTransform: 'none',
                fontSize: '16px',
              }}
              onClick={() => {
                handleClickCustomMenu(val);
              }}
              key={val._id}
            >
              {val.projectName.slice(0, 22)}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
