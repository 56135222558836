import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { vocabulary } from '../../../../services/vocabulary';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { sourceJSON } from '../../../../services/source';

// ... (imports)

export default function Sources({ lang, putSelectedValue }) {
  const [anchor0, setAnchor0] = useState(null);
  const [anchor1, setAnchor1] = useState(null);
  const [anchor2, setAnchor2] = useState(null);
  const [anchor3, setAnchor3] = useState(null);
  const [anchor4, setAnchor4] = useState(null);
  const [anchor5, setAnchor5] = useState(null);
  const [anchor6, setAnchor6] = useState(null);
  const [anchor7, setAnchor7] = useState(null);
  const [anchor8, setAnchor8] = useState(null);
  const entriesArray = Object.entries(sourceJSON);
  const [menu, setMenu] = useState(null);
  const [menu1Level, setMenu1Level] = useState(null);
  const [menu2Level, setMenu2Level] = useState(null);
  const [menu3Level, setMenu3Level] = useState(null);
  const [menu4Level, setMenu4Level] = useState(null);
  const [menu5Level, setMenu5Level] = useState(null);
  const [menu6Level, setMenu6Level] = useState(null);
  const [menu7Level, setMenu7Level] = useState(null);
  const openMenu0 = Boolean(anchor0);
  const openMenu1 = Boolean(anchor1);
  const openMenu2 = Boolean(anchor2);
  const openMenu3 = Boolean(anchor3);
  const openMenu4 = Boolean(anchor4);
  const openMenu5 = Boolean(anchor5);
  const openMenu6 = Boolean(anchor6);
  const openMenu7 = Boolean(anchor7);
  const openMenu8 = Boolean(anchor8);
  const handleMenu0Click = (event) => {
    setAnchor0(event.currentTarget);
  
  };

  const handleMenu1Click = (e, val) => {
    setAnchor1(e.currentTarget);
    setMenu(val);
 
  };

  const handleMenu2Click = (event, val) => {
    setAnchor2(event.currentTarget);
    setMenu1Level(val);
  
  };

  const handleMenu3Click = (event, val) => {

    if (val.box) {
      putSelectedValue(val.name, val.description, val.sources, val.colors);
      handleClose();
    } else {
      setAnchor3(event.currentTarget);
      setMenu2Level(val);
    }
  };

  const handleMenu4Click = (event, key, val) => {


    if (val.box) {
      putSelectedValue(val.name, val.description, val.sources, val.colors);
      handleClose();
    } else {
      setAnchor4(event.currentTarget);
      setMenu3Level(key);
    }
  };

  const handleMenu5Click = (event, key, val) => {
  

    if (val.hasOwnProperty('box')) {
      if (val.box) {
        putSelectedValue(val.name, val.description, val.sources, val.colors);
        handleClose();
      }
    } else {
      setAnchor5(event.currentTarget);
      setMenu4Level(key);
    }
  };
  const handleMenu6Click = (event, key, val) => {
 

    if (val.hasOwnProperty('box')) {
      if (val.box) {
        putSelectedValue(val.name, val.description, val.sources, val.colors);
        handleClose();
      }
    } else {
      if (!val.hasOwnProperty('box')) {
        setAnchor6(event.currentTarget);
        setMenu5Level(key);
      }
    }
  };
  const handleMenu7Click = (event, key, val) => {
  

    if (val.hasOwnProperty('box')) {
      if (val.box) {
        putSelectedValue(val.name, val.description, val.sources, val.colors);
        handleClose();
      }
    } else {
      if (!val.hasOwnProperty('box')) {
        setAnchor7(event.currentTarget);
        setMenu6Level(key);
      }
    }
  };
  const handleMenu8Click = (event, key, val) => {


    if (val.hasOwnProperty('box')) {
      if (val.box) {
        putSelectedValue(val.name, val.description, val.sources, val.colors);
        handleClose();
      }
    } else {
      setAnchor8(event.currentTarget);
      setMenu7Level(key);
    }
  };

  const handleClose = () => {
    setAnchor0(null);
    setAnchor1(null);
    setAnchor2(null);
    setAnchor3(null);
    setAnchor4(null);
    setAnchor5(null);
    setAnchor6(null);
    setAnchor7(null);
    setMenu(null);
    setMenu1Level(null);
    setMenu2Level(null);
    setMenu3Level(null);
    setMenu4Level(null);
    setMenu5Level(null);
    setMenu6Level(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={openMenu0 ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu0 ? 'true' : undefined}
        onClick={handleMenu0Click}
        style={{
          color: 'black',
          textTransform: 'none',
          fontSize: '16px',
          paddingRight: '0px',
        }}
      >
        {vocabulary.sources[lang]}
        <KeyboardArrowDownIcon
          style={{
            fontSize: 'medium',
          }}
        />
      </Button>
      <Menu
        id="menu-0"
        anchorEl={anchor0}
        open={openMenu0}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {entriesArray.map(([key, value]) => {
          return (
            <MenuItem
              key={key}
              id={key}
              onClick={(e) => handleMenu1Click(e, key)}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '16px',
              }}
            >
              {value?.name[lang === 1 ? 'es' : 'en']}
              <KeyboardArrowRightIcon
                style={{
                  fontSize: 'medium',
                }}
              />
            </MenuItem>
          );
        })}
      </Menu>

      {/* menu1 */}
      <Menu
        id="menu-1"
        anchorEl={anchor1}
        open={openMenu1}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'submenu-button',
        }}
      >
        {menu &&
          Object.entries(sourceJSON[menu].items).map(([key, value]) => {
            return (
              <MenuItem
                key={key}
                onClick={(e) => handleMenu2Click(e, key)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '16px',
                }}
              >
                {value.name?.[lang === 1 ? 'es' : 'en']}
                <KeyboardArrowRightIcon
                  style={{
                    fontSize: 'medium',
                  }}
                />
              </MenuItem>
            );
          })}
      </Menu>
      {/* menu2 */}
      <Menu
        id="menu-2"
        anchorEl={anchor2}
        open={openMenu2}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'submenu-button',
        }}
      >
        {menu1Level &&
          Object.entries(sourceJSON[menu].items[menu1Level].items).map(
            ([key, value]) => {
              return (
                <MenuItem
                  key={key}
                  onClick={(e) => handleMenu3Click(e, key)}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '16px',
                  }}
                >
                  {value.name ? value.name[lang === 1 ? 'es' : 'en'] : null}
                  <KeyboardArrowRightIcon
                    style={{
                      fontSize: 'medium',
                    }}
                  />
                </MenuItem>
              );
            }
          )}
      </Menu>

      {/* menu3 */}
      <Menu
        id="menu-3"
        anchorEl={anchor3}
        open={openMenu3}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'submenu-button',
        }}
      >
        {menu2Level &&
          Object.entries(
            sourceJSON[menu].items[menu1Level].items[menu2Level].items
          ).map(([key, value]) => {
            return (
              <MenuItem
                key={key}
                onClick={(e) => handleMenu4Click(e, key, value)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '16px',
                }}
              >
                {value.name[lang === 1 ? 'es' : 'en']}

                {!value.hasOwnProperty('box') && (
                  <KeyboardArrowRightIcon
                    style={{
                      fontSize: 'medium',
                    }}
                  />
                )}
              </MenuItem>
            );
          })}
      </Menu>
      {/* menu4 */}
      <Menu
        id="menu-4"
        anchorEl={anchor4}
        open={openMenu4}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'submenu-button',
        }}
      >
        {menu3Level &&
          Object.entries(
            sourceJSON[menu].items[menu1Level].items[menu2Level].items[
              menu3Level
            ].items
          ).map(([key, value]) => {
            return (
              <MenuItem
                key={key}
                onClick={(e) => handleMenu5Click(e, key, value)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '16px',
                }}
              >
                {value.name[lang === 1 ? 'es' : 'en']}

                {!value.hasOwnProperty('box') && (
                  <KeyboardArrowRightIcon
                    style={{
                      fontSize: 'medium',
                    }}
                  />
                )}
              </MenuItem>
            );
          })}
      </Menu>

      {/* menu5 */}
      <Menu
        id="menu-5"
        anchorEl={anchor5}
        open={openMenu5}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'submenu-button',
        }}
      >
        {menu4Level &&
          Object.entries(
            sourceJSON[menu].items[menu1Level].items[menu2Level].items[
              menu3Level
            ].items[menu4Level].items
          ).map(([key, value]) => {
         

            return (
              <MenuItem
                key={key}
                onClick={(e) => handleMenu6Click(e, key, value)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '16px',
                }}
              >
                {value.name[lang === 1 ? 'es' : 'en']}

                {!value.hasOwnProperty('box') && (
                  <KeyboardArrowRightIcon
                    style={{
                      fontSize: 'medium',
                    }}
                  />
                )}
              </MenuItem>
            );
          })}
      </Menu>
      {/* menu6 */}
      <Menu
        id="menu-6"
        anchorEl={anchor6}
        open={openMenu6}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'submenu-button',
        }}
      >
        {menu5Level &&
          Object.entries(
            sourceJSON[menu].items[menu1Level].items[menu2Level].items[
              menu3Level
            ].items[menu4Level].items[menu5Level].items
          ).map(([key, value]) => {

            return (
              <MenuItem
                key={key}
                onClick={(e) => handleMenu7Click(e, key, value)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '16px',
                }}
              >
                {value.name[lang === 1 ? 'es' : 'en']}

                {!value.hasOwnProperty('box') && (
                  <KeyboardArrowRightIcon
                    style={{
                      fontSize: 'medium',
                    }}
                  />
                )}
              </MenuItem>
            );
          })}
      </Menu>
      {/* menu7 */}
      <Menu
        id="menu-7"
        anchorEl={anchor7}
        open={openMenu7}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'submenu-button',
        }}
      >
        {menu5Level &&
          Object.entries(
            sourceJSON[menu].items[menu1Level].items[menu2Level].items[
              menu3Level
            ].items[menu4Level].items[menu5Level].items[menu6Level]
          ).map(([key, value]) => {
    
            return (
              <MenuItem
                key={key}
                onClick={(e) => handleMenu8Click(e, key, value)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '16px',
                }}
              >
                {value.name[lang === 1 ? 'es' : 'en']}

                {!value.box && (
                  <KeyboardArrowRightIcon
                    style={{
                      fontSize: 'medium',
                    }}
                  />
                )}
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
}
