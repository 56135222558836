import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../GlobalContext';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GoogleAuth from './GoogleAuth';
import logoIcon from './../../images/logo.png';
import { vocabulary } from '../../services/vocabulary';
import logo from '../../images/logo.png';
import footer1 from '../../images/footer1.png';
import FirstModal from '../Components/FirstModal';
import footer2 from '../../images/footer2.png';
function Login() {
  const { isLoggedIn } = useContext(GlobalContext);
  const [email, setEmail] = useState('');
  const { state, GlobalActions, langSelected } = useContext(GlobalContext);
  const [showLoginError, setShowLoginError] = useState(false);
  const [password, setUserPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [googleError, setGoogleError] = useState(false);
  const navigate = useNavigate();
  const [lang, setLang] = useState(0);
  const [firstModalOpen, setFirstModalOpen] = useState(true);
  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (state.userToken) {
      navigate('/');
    }
  }, [state]);

  useEffect(() => {}, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGoogleError(false);
    setShowLoginError(false);

    let res = await GlobalActions.logIn({
      email: email,
      password: password,
    });

    if (res === 'bad credentials') {
      setShowLoginError(true);
    }
  };

  const handleOnClickSignUp = () => {
    navigate('/signup');
  };
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSetShowLogInError = () => {
    setGoogleError(true);
  };

  return (
    <div className="login-screen">
       
      {firstModalOpen && (
        <FirstModal
          aboutOpen={firstModalOpen}
          setAboutOpen={setFirstModalOpen}
        />
      )}
      <div className="image-login">
      <img src={footer1} alt="Logos"  style={{width: "95%" , backgroundColor: "rgba(255, 255, 255, 0.4)"}}/>
      

      </div>
      <div className="login-container">
        <div className="login-box">
          <form className="form-login" onSubmit={handleSubmit}>
            <img src={logo} alt="Logo" width="150px" />
            <div className="form-group">
              <div className="input-div-login-text">
                <div className="login-header">
                  {vocabulary.startRoadmovie[lang]}
                </div>
              </div>
              <label htmlFor="e-mail"></label>
              <div className="input-div">
                <TextField
                  variant="outlined"
                  autoComplete="username"
                  fullWidth
                  style={{ color: 'white', height: '40px' }}
                  margin="dense"
                  placeholder={vocabulary.userName[lang]}
                  name="userName"
                  value={email}
                  onChange={(e) => {
                    setShowLoginError(false);

                    setEmail(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <label htmlFor="password"></label>
              <div className="input-div">
                <TextField
                  variant="outlined"
                  style={{ color: 'white', height: '40px' }}
                  fullWidth
                  margin="dense"
                  placeholder={vocabulary.password[lang]}
                  name="password"
                  value={password}
                  autoComplete="current-password"
                  onChange={(e) => {
                    setShowLoginError(false);
                    setUserPassword(e.target.value);
                  }}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="input-div-under">
                <div
                  className="forgot-my-password"
                  onClick={() => {
                    navigate('/recovery');
                  }}
                >
                  {vocabulary.passwordForgotten[lang]}
                </div>
              </div>
              <div className="input-div">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    width: '100%',
                    height: '40px',
                    marginTop: '5px',
                    backgroundColor: '#5A58E6',
                    color: 'white',
                    textTransform: 'none',
                  }}
                  fullWidth
                  margin="dense"
                  onClick={handleSubmit}
                >
                  {vocabulary.enter[lang]}
                </Button>
              </div>
              <div className="input-div-under">
                <div
                  className="not-have-an-account"
                  onClick={handleOnClickSignUp}
                >
                  {vocabulary.doNotHaveAccount[lang]}
                </div>
              </div>
              <GoogleAuth
                login={true}
                setShowLogInError={handleSetShowLogInError}
                width={400}
              />
              <div className="input-div">
                {googleError && (
                  <div className="error-message">
                    {vocabulary.doNotRegistered[lang]}
                  </div>
                )}
                {showLoginError && (
                  <div className="error-message">
                    {vocabulary.errorMailPassword[lang]}
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
        <img src={footer2} alt="Logos"  style={{width: "29%" , backgroundColor: "rgba(255, 255, 255, 0.4)",position:"absolute",right:"0",bottom:'0'}}/>
      </div>
    </div>
  );
}

export default Login;
