import React, { useContext } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import GlobalContext from '../../GlobalContext';

export default function GoogleAuth({
  login,
  setShowSignUpError,
  setShowLogInError,
  code,
  width,
}) {
  const { GlobalActions, state } = useContext(GlobalContext);

  return (
    <div className="google-button">
      <GoogleLogin
        width={width}
        size={'large'}
        onSuccess={(credentialResponse) => {
          if (!login) {
            let res = async () => {
              credentialResponse.code = code;
              let result = await GlobalActions.signUp(credentialResponse);

              if (result === 'User already exists.') {
                setShowSignUpError('El usuario ya existe');
                return;
              } else if (result === 'Code incorrect.') {
                setShowSignUpError('Código equivocado');
                return;
              }
            };
            res();
          } else {
            let res = async () => {
              let result = await GlobalActions.logIn(credentialResponse);

              if (result === 'bad credentials') {
                setShowLogInError(true);
                return;
              }
            };
            res();
          }
        }}
        onError={() => {
          console.log('Login Failed');
        }}
      />
    </div>
  );
}
