import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import backIcon from '../../../images/back-arrow.png';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { vocabulary } from '../../../services/vocabulary';
import {
  changeUserInformation,
  deleteSource,
  deleteUser,
  getSource,
  getUserInformation,
} from '../../../api/workFlowApi';
import DeleteAccount from './DeleteAccount';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GlobalContext from '../../../GlobalContext';

import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';

import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import logo from './../../../images/logo.png';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function MyAccount({ showAccount, setShowAccount }) {
  const { GlobalActions, langSelected } = useContext(GlobalContext);
  const [email, setAdminEmail] = useState('');
  const [password, setUserPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState(false);
  const [showSignUpError, setShowSignUpError] = useState(false);
  const [showCodeError, setShowCodeError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [lang, setLang] = useState(0);
  const [code, setCode] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  useEffect(() => {
    if (langSelected === 'en') {
      setLang(0);
    } else {
      setLang(1);
    }
  }, [langSelected]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === '' || showEmailErrorMessage) {
      setShowEmailErrorMessage(true);
      return;
    }
    setShowSignUpError(false);
    setShowCodeError(false);

    if (password === confirmPassword) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);

      return;
    }

    let res = await changeUserInformation({
      email,
      password,
      userName,
      surname: '',
      gender: '',
      age: '',
      userType: 'admin',
    });
    setShowAccount(false);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (emailRegex.test(email) || email === '') {
      setAdminEmail(email);
      setShowEmailErrorMessage(false);
    } else {
      setAdminEmail(email);
      setShowEmailErrorMessage(true);
    }
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setUserPassword(password);
    setPasswordsMatch(true);

    const consecutiveRepeatingRegex = /(.)\1{2,}/;

    if (password.length < 6) {
      setPasswordError(vocabulary.passwordLongitudError[lang]);
    } else if (consecutiveRepeatingRegex.test(password)) {
      setPasswordError(vocabulary.passwordRepeatSymbolsError[lang]);
    } else {
      setPasswordError('');
    }
  };
  const handleSetShowSignUpError = (val) => {
    setShowSignUpError(val);
  };
  const handleConfirmPasswordChange = (event) => {
    setPasswordsMatch(true);
    setConfirmPassword(event.target.value);
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleCloseClick = () => {
    setModalOpen(!modalOpen);
  };

  const handleClose = () => {
    setShowAccount(false);
  };
  const handleDelete = () => {
    setDeleteOpen(!deleteOpen);
    console.log('DELETE');
  };
  React.useEffect(() => {
    let getInfo = async () => {
      let result = await getUserInformation();
      let data = result.data.userData;
   
      setUserName(data.userName);
      setAdminEmail(data.email);
    };
    getInfo();
  }, []);

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        sx={{
          '& .MuiDialog-paper': {
            position: 'fixed',
            right: -32,
            top: -32,
            bottom: 0,
            height: '100%',
            maxHeight: '100%',
            overflowY: 'auto',
          },
        }}
      >
        <div className="header-chat">
          <DeleteAccount
            deleteOpen={deleteOpen}
            setDeleteOpen={setDeleteOpen}
            handleDelete={handleDelete}
            handleClose={handleClose}
          />
          <img
            src={backIcon}
            alt="back-icon"
            className="chat-icon-back"
            onClick={handleClose}
          />
          <h4 className="my-project-name">
            {vocabulary.myAccount[langSelected]}
          </h4>
        </div>

        <DialogContent
          sx={{
            // Target the dialog paper element
            // Add scroll for overflow content
            overflowX: 'hidden',
          }}
          dividers
        >
          <form className="form-login" onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="input-div-login-text"></div>

              <TextField
                variant="outlined"
                autoComplete="username"
                fullWidth
                size="small"
                margin="normal"
                placeholder={vocabulary.namesAndSurnames[langSelected]}
                name="userName"
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                variant="outlined"
                autoComplete="email"
                fullWidth
                size="small"
                margin="normal"
                placeholder={vocabulary.email[langSelected]}
                name="email"
                value={email}
                onChange={handleEmailChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />

              {showEmailErrorMessage && (
                <div className="error-message">
                  {vocabulary.emailErrorBlank[langSelected]}
                </div>
              )}

              <TextField
                variant="outlined"
                style={{ color: 'white' }}
                fullWidth
                size="small"
                margin="normal"
                placeholder={vocabulary.password[langSelected]}
                name="password"
                value={password}
                autoComplete="current-password"
                onChange={handlePasswordChange}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {passwordError && (
                <div className="error-message">{passwordError}</div>
              )}

              <TextField
                variant="outlined"
                style={{ color: 'white' }}
                fullWidth
                size="small"
                margin="normal"
                placeholder={vocabulary.confirmPassword[langSelected]}
                name="confirm-password"
                value={confirmPassword}
                autoComplete="current-password"
                onChange={handleConfirmPasswordChange}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="input-div">
              {!passwordsMatch && (
                <div className="error-message">
                  {vocabulary.passwordDoesNotMatch[langSelected]}
                </div>
              )}

              {showSignUpError && (
                <div className="error-message-code">
                  {vocabulary.emailError[langSelected]}
                </div>
              )}

              <Button
                variant="outlined"
                color="primary"
                size="small"
                style={{
                  width: '100%',
                  height: '40px',
                  marginTop: '20px',
                  backgroundColor: '#5A58E6',
                  color: 'white',
                  textTransform: 'none',
                  borderRadius: '10px',
                }}
                fullWidth
                margin="normal"
                onClick={handleSubmit}
              >
                {vocabulary.actualize[langSelected]}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                style={{
                  width: '100%',
                  height: '40px',
                  marginTop: '20px',
                  backgroundColor: '#EE4037',
                  textTransform: 'none',
                  borderRadius: '10px',

                  color: 'white',
                }}
                fullWidth
                margin="normal"
                onClick={handleDelete}
              >
                {vocabulary.deleteAccount[langSelected]}
              </Button>
            </div>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
