import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import { searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useEffect, useState } from 'react';
import '@react-pdf-viewer/search/lib/styles/index.css';

export default function ThumbnailPdf({ file, currentPage, searchString }) {
  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const { ZoomOut } = slots;
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
            }}
          ></div>
        );
      }}
    </Toolbar>
  );

  const [isDocumentLoaded, setDocumentLoaded] = useState(false);
  const handleDocumentLoad = (e) => {
    const { activateTab } = defaultLayoutPluginInstance;
    activateTab(0);
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });

  const specialSymbols = ['/', '\\', '(', ')', '{', '}', '.'];

  const regexPattern = new RegExp(
    `[${specialSymbols.map((symbol) => '\\' + symbol).join('')}]`
  );
  const resultArray = searchString.split(regexPattern);

  let result = resultArray.filter((val) => {
    if (val.length > 30) {
      return val;
    }
  });

  const searchPluginInstance = searchPlugin({
    keyword: result,
  });
  const { highlight } = searchPluginInstance;

  useEffect(() => {
    if (isDocumentLoaded) {
      highlight({
        keyword: 'ayuda percibida',
      });
    }
  }, [isDocumentLoaded]);
  const [newDoc, setNewDoc] = useState(true);
  useEffect(() => {
    setNewDoc(false);

    setTimeout(() => {
      setNewDoc(true);
    }, 1000);
  }, [currentPage]);
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <div
        style={{ height: '90%', width: '95%', border: 'none', margin: 'auto' }}
      >
        {newDoc && (
          <Viewer
            fileUrl={file}
            plugins={[defaultLayoutPluginInstance, searchPluginInstance]}
            onDocumentLoad={handleDocumentLoad}
            initialPage={currentPage}
          />
        )}
      </div>
    </Worker>
  );
}
