import * as React from 'react';
import backIcon from '../../../../images/back-arrow.png';
import logoIcon from '../../../../images/logo.png';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { vocabulary } from '../../../../services/vocabulary';
import { deleteSource, getSource } from '../../../../api/workFlowApi';
import DeleteSource from './DeleteSource';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function ChooseSources({
  setNewSourceExist,
  newSourceExist,
  langSelected,
  sourcesToChoose,
  setSourcesToChoose,
  sourceToChooseHandle,
  setAlertOpen,
  setHeaderText,
  setAlertText,
}) {
  const [sourcesArray, setSourcesArray] = React.useState([]);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [guide, setGuide] = React.useState('');
  const [fileName, setFileName] = React.useState('');
  const [valToDelete, setValToDelete] = React.useState('');
  React.useEffect(() => {
    let res = async () => {
      let result = await getSource();
      setSourcesArray(result);
    };

    res();
  }, []);

  const handleDelete = async () => {
    let val = valToDelete;
    let files = val.files.map((el) => {
      return JSON.parse(el);
    });

    val.files = files;

    await deleteSource(val);
    setAlertOpen(true);
    setHeaderText(vocabulary.sourceDeleted[langSelected]);
    setAlertText(vocabulary.sourceDeletedText[langSelected]);

    setNewSourceExist(!newSourceExist);
    handleClose();
  };

  const handleClose = () => {
    setSourcesToChoose(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        sx={{
          '& .MuiDialog-paper': {
            position: 'fixed',
            right: -32,
            top: -32,
            bottom: 0,
            height: '100%',
            maxHeight: '100%',
            overflowY: 'auto',
          },
        }}
      >
        <div className="header-chat">
          <DeleteSource
            deleteOpen={deleteOpen}
            setDeleteOpen={setDeleteOpen}
            handleDelete={handleDelete}
            handleClose={handleClose}
            setAlertOpen={setAlertOpen}
            setHeaderText={setHeaderText}
            setAlertText={setAlertText}
          />
          <img
            src={backIcon}
            alt="back-icon"
            className="chat-icon-back"
            onClick={handleClose}
          />
          <h4 className="my-project-name">
            {vocabulary.chooseSource[langSelected]}
          </h4>
        </div>

        <DialogContent
          sx={{
            // Target the dialog paper element
            // Add scroll for overflow content
            overflowX: 'hidden',
          }}
          dividers
        >
          <div className="sources-div">
            {sourcesArray.map((val) => {
              return (
                <div className="items-source" key={val._id}>
                  <img
                    src={logoIcon}
                    alt="back-icon"
                    onClick={handleClose}
                    width="50px"
                    height="25px"
                  />

                  <div className="name-source">
                    <div className="name-source-header">
                      {val.projectName.slice(0, 35)}
                    </div>
                  </div>

                  <div className="description-source"></div>
                  <div className="button-div">
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      style={{
                        fontSize: '8px',
                        marginTop: '10px',
                        backgroundColor: '#5A58E6',
                        color: 'white',
                        textTransform: 'none',
                      }}
                      fullWidth
                      margin="normal"
                      onClick={() => {
                        sourceToChooseHandle(val);
                        handleClose();
                      }}
                    >
                      {vocabulary.changeSource[langSelected]}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      style={{
                        fontSize: '8px',
                        marginTop: '10px',
                        backgroundColor: '#EE4037',
                        color: 'white',
                        textTransform: 'none',
                      }}
                      fullWidth
                      margin="normal"
                      onClick={() => {
                        setValToDelete(val);
                        setDeleteOpen(!deleteOpen);
                      }}
                    >
                      {vocabulary.deleteSource[langSelected]}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
