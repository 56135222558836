import { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { vocabulary } from '../../../../services/vocabulary';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useContext } from 'react';
import { getProject } from '../../../../api/workFlowApi';
import GlobalContext from '../../../../GlobalContext';

export default function MyProjects({
  lang,
  downloadJsonFile,
  uploadJsonFile,
  newProject,
  setAlertOpen,
  setHeaderText,
  setAlertText,
  saveConfig,
  setNewProjectOpen,
  newProjectExist,
  setProjectToChoose,
  setProjectLoadOpen,
}) {
  const { GlobalActions, langSelected } = useContext(GlobalContext);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const parsedData = JSON.parse(e.target.result);
          uploadJsonFile(parsedData);
          handleClose();
        } catch (error) {
          console.error('Error parsing JSON:', error);
          alert('Choose another file, this is bad... ');
          handleClose();
          // Handle error, e.g., display an error message to the user
        }
      };

      reader.readAsText(file);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const [arrayProject, setArrayProject] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadProject = () => {
    downloadJsonFile();
    handleClose();
  };
  const saveProject = () => {
    setNewProjectOpen(true);
    handleClose();
  };

  const changeProject = () => {
    setProjectToChoose(true);
    handleClose();
  };

  const onclickNewProject = () => {
    GlobalActions.setRedactorState(null);
    newProject();
    handleClose();
  };

  useEffect(() => {
    let getInfo = async () => {
      let res = await getProject();
      setArrayProject(res);
    };

    getInfo();
  }, [newProjectExist]);
  const handleClickProject = (val) => {
    uploadJsonFile(JSON.parse(val.data));

    handleClose();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          color: 'black',
          textTransform: 'none',
          fontSize: '16px',
          paddingRight: '0px',
        }}
      >
        {vocabulary.archives[lang]}
        <KeyboardArrowDownIcon
          style={{
            color: 'black',
            textTransform: 'none',
            fontSize: 'medium',
          }}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          style={{
            color: 'black',
            textTransform: 'none',
            fontSize: '16px',
          }}
          onClick={onclickNewProject}
        >
          {vocabulary.newProject[lang]}
        </MenuItem>
        {/* <MenuItem style={{
            color: 'black',
            textTransform: 'none',
            fontSize:"16px"
          }} onClick={downloadProject}>
          {vocabulary.downloadProject[lang]}
        </MenuItem>
        <MenuItem style={{
            color: 'black',
            textTransform: 'none',
            fontSize:"16px"
          }} onClick={handleButtonClick}>
          {vocabulary.uploadProject[lang]}
          <div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileUpload}
              style={{
                display: 'none',
              }}
            />
          </div>
        </MenuItem>  */}

        <MenuItem
          style={{
            color: 'black',
            textTransform: 'none',
            fontSize: '16px',
          }}
          onClick={() => {
            setProjectLoadOpen(true);
            handleClose();
          }}
        >
          {vocabulary.loadProject[lang]}
        </MenuItem>

        <MenuItem
          style={{
            color: 'black',
            textTransform: 'none',
            fontSize: '16px',
          }}
          onClick={saveProject}
        >
          {vocabulary.saveProject[lang]}
        </MenuItem>

        <MenuItem
          style={{
            color: 'black',
            textTransform: 'none',
            fontSize: '16px',
          }}
          onClick={changeProject}
        >
          {vocabulary.changeProject[lang]}
        </MenuItem>
        {arrayProject?.length > 0 && (
          <div className="menu-line">
            {vocabulary.lastProject[langSelected]}
          </div>
        )}

        <div className="menu-project">
          {arrayProject?.reverse().map((val, index) => {
            return (
              <MenuItem
                style={{
                  color: 'black',
                  textTransform: 'none',
                  fontSize: '16px',
                }}
                onClick={() => {
                  handleClickProject(val);
                }}
                key={val._id}
              >
                {val.projectName.slice(0, 22)}
              </MenuItem>
            );
          })}{' '}
        </div>
      </Menu>
    </div>
  );
}
